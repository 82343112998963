import React from 'react';
import {Row, Col} from 'react-bootstrap'; 
require('./NoMatch.css')

export const NoMatch = () => (
    <div className="nomatch-body">
        <div className="jumbo">
            <Row>
                <Col>
                    <div className="jumbo-content-left">
                        <h2>The page you are looking for is not available.</h2>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
)
