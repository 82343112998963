import React from 'react'
import {
    OfferedService,
    RequestedService,
    ServiceLocation,
    Provider,
    Requestor
} from '../../Constructors/Service'
import {
    Row,
    Col,
    Container,
    Form,
    Collapse,
    Button
} from 'react-bootstrap'
import { connect } from 'react-redux';
import AutoComplete from '../../AutoComplete/AutoComplete'
import Far from '../../Spinners/Far'
import { Error, Primary, Success, Info, Warning } from '../../Alerts'
import { uploadImages } from '../../store/actions/userActions';
import { TiDeleteOutline } from 'react-icons/ti'
import { BrowserRouter as Router, Redirect } from "react-router-dom";




require('./AddService.css')


class AddService extends React.Component {

    state = {
        redirect: false,
        target: null,
        service: null,
        profile: null,
        openSubmit: false,
        location: null,
        loading: false,
        image: null,
        imageData: null,
        catagories: {
            "Select Catagory": [
                "select Sub Catagory"
            ],
            "General Contractor": [
                "Kitchen Remodel",
                "Custom Home Build",
                "Bathroom Remodel",
                "Roof Repair",
                "Pool Installation",
                "Home Improvement",
                "Outdoor Landscaping",
                "Home Security/Alarm Installation"
            ],

            "Cleaning": [
                "Bathroom Cleaning",
                "Carpet Cleaning",
                "Floor CLeaning",
                "Home Cleaning",
                "Kitchen Cleaning",
                "Sofa Cleaning",
                "Pressure Washing",
                "Gutter Cleaning"
            ],
            "Lending": [
                "Construction",
                "Rental Loans",
                "Fix and Flip Loans",
                "Home Purchase",
                "Land Purchase",
                "Short-Tearm Loans",
                "Cash Purchase",
                "Fundraising"
            ],
            "Plumbing": [
                "Back Flow Prevention",
                "Bathtubs & Shower",
                "Drain Cleaning",
                "Gas Piping",
                "Leaky Toilet",
                "Slab Leak Detection",
                "Water Heater",
                "Water Line Repair"
            ],
            "Property Management": [
                "Property Management",
                "Canada Property Management"
            ]
        }
    }
    componentDidMount() {
        const { profile, user } = this.props;
        this.setState({
            profile: profile
        })
        var provider = new Provider(profile);
        console.log()
        this.setState({
            service: new OfferedService({ provider: provider, id: "test", created: "test" })
        })

    }


    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.target} />
        }
    }

    handleType = (event) => {
        var value = event.target.checked
        var type = event.target.id
        var serviceValue = null
        switch (type) {
            case 'fixedCost':
                serviceValue = value ? 'fixedCost' : 'hourly'
                break;
            case 'hourly':
                serviceValue = value ? 'hourly' : 'fixedCost'
                break;
            default:
                serviceValue = null;
                break;
        }

        this.handleFieldChange("type", serviceValue)
    }

    handleFieldChange = (field, value) => {
        var serviceState = this.state.service ? this.state.service :
            new OfferedService({ provider: new Provider(this.state.profile), id: "test", created: "test" })
        serviceState.set(field, value)
        this.setState({ service: serviceState })
    };

    removeImage = () => {
        this.setState({
            image: null,
            imageData: null
        })
    }

    removeImageCurrent = () => {
        var service = this.state.service
        service.removeImage()
        this.setState({
            service: service
        })
    }

    imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({ image: reader.result })
            }
        }
        reader.readAsDataURL(e.target.files[0])
        this.setState({ imageData: e.target.files[0] })
    };

    submitImage = (e, cb) => {
        e.preventDefault();
        this.props.uploadImages([this.state.imageData], 'service_images', (response) => {
            cb(response)
        });
    }

    handleSubmit = (e) => {

        this.setState({
            loading: true
        })
        var service = this.state.service

        if (this.state.image && this.state.imageData) {
            this.submitImage(e, url => {
                if (url && Array.isArray(url) && url.length > 0 && url[0] !== 'undefined') {
                    service.setImage(url[0])
                    service.submit("offered", res => {
                        this.setState({
                            loading: false
                        })
                        if (res.success) {
                            this.setState({
                                redirect: true,
                                target: '/my-offered-services'
                            });
                            Success({ title: "Success", message: "Service Successfully added" })
                        } else {
                            Error({ title: "Failed", message: "Service failed to upload" })
                        }
                        console.log("submit service response", res)
                    })
                }
            })
        } else {
            service.submit("offered", res => {
                this.setState({
                    loading: false
                })
                if (res.success) {
                    this.setState({
                        redirect: true,
                        target: '/my-offered-services'
                    });
                    Success({ title: "Success", message: "Service Successfully added" })
                } else {
                    Error({ title: "Failed", message: "Service failed to upload" })
                }
                console.log("submit service response", res)
            })
        }

    }

    Submitting = () => {
        return (
            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <Container>
                    <div style={{ height: '50px' }} />
                    <h5 className='loading'>Adding Service...</h5>
                    <p></p>
                    <p></p>
                    {/* <Spinner animation='border' variant='primary' /> */}
                    {/* <Invest /> */}
                    <Far />
                    <p></p>
                    <p></p>
                    <div style={{ height: '100px' }} />
                </Container>

            </div>
        )
    }

    UploadService = () => {
        var setLocation = (loc) => {
            var service = this.state.service
            console.log(loc)
            service.setLocation(loc)
            this.setState({
                service: service
            })
        }
        return (<Container>
            {this.renderRedirect()}
            <div style={{ height: '40px' }} />
            <div className="add-service-card">
                <div className='add-service-header'>
                    <Row>
                        <Col>
                            <Row>
                                <h6>Add Service</h6>
                            </Row>
                            <Row>
                                <div className='small-header-row-service'></div>
                            </Row>
                        </Col>
                    </Row>

                </div>
                <div className="service-content">
                    <Row>
                        <Col sm={2}>
                            <input className='custom-input' type="file" accept="image/*" id="profileChange" onChange={(e) => this.imageHandler(e)} />
                        </Col>
                        <Col>
                            <Row>
                                {this.state.image == null ? null
                                    : <Col sm={2} className="pic-holder">
                                        <div className='pic'>
                                            <div className='remove' onClick={() => this.removeImage()}>
                                                <TiDeleteOutline className='icon' />
                                            </div>
                                            <img className="img-responsive" src={this.state.image} alt="img" title="img" />
                                        </div>
                                    </Col>
                                }
                                {this.state.service && this.state.service.getImage() == null ? null
                                    : <Col sm={2} className="pic-holder">
                                        <div className='pic'>
                                            <div className='remove' onClick={() => this.removeImageCurrent()}>
                                                <TiDeleteOutline className='icon' />
                                            </div>
                                            <img className="img-responsive" src={this.state.service ? this.state.service.getImage() : ""} alt="img" title="img" />
                                        </div>
                                    </Col>
                                }
                            </Row>

                        </Col>
                    </Row>
                    <Form.Label>Service Type *</Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} sm={6} controlId="propertyType">
                            <Form.Check
                                checked={this.state.service ? this.state.service.getType() == "fixedCost" : false}
                                onChange={(event) => this.handleType(event)}
                                id='fixedCost'
                                type="checkbox"
                                label="Fixed Cost Service" />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="propertyType">
                            <Form.Check
                                checked={this.state.service ? this.state.service.getType() == "hourly" : false}
                                onChange={(event) => this.handleType(event)}
                                id='hourly'
                                type="checkbox"
                                disabled={this.state.anyType}
                                label="Hourly Based Service" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Service Name *</Form.Label>
                        <Form.Control className='form-input' placeholder="Service Name"
                            onChange={(event) => this.handleFieldChange('name', event.target.value)} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Service Catagory *</Form.Label>
                            <Form.Control as="select" defaultValue="Select Catagory"
                                onChange={(event) => this.handleFieldChange('catagory', event.target.value)}>
                                {Object.keys(this.state.catagories).map(catagory => {
                                    return <option>{catagory}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Sub Catagory *</Form.Label>
                            <Form.Control as="select" defaultValue="Select Sub Catagory"
                                onChange={(event) => this.handleFieldChange('subCatagory', event.target.value)}>
                                <option>Sub Catagory </option>
                                {this.state.service && this.state.service.getCatagory() ?
                                    this.state.catagories[this.state.service.getCatagory()] ?
                                        this.state.catagories[this.state.service.getCatagory()].map(sub => {
                                            return <option>{sub}</option>
                                        }) : <option>Sub Catagory </option> : <option>Sub Catagory </option>}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    {/* <Form.Group>
                        <Form.Label>Location  *</Form.Label>
                        <Form.Control className='form-input' placeholder="Service Location"
                            onChange={(event) => this.handleFieldChange('title', event.target.value)} />
                    </Form.Group> */}
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Please search for address below</Form.Label>
                            <AutoComplete type={"service"} style={{ width: '100%' }} setLocation={setLocation} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Price  *</Form.Label>
                        <Form.Control className='form-input' placeholder="Enter Price"
                            onChange={(event) => this.handleFieldChange('price', event.target.value)} />
                    </Form.Group>
                    <Form.Group
                        controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            onChange={(event) => this.handleFieldChange('description', event.target.value)}
                            as="textarea"
                            rows={3} />
                    </Form.Group>

                    <div className='submit-button-container'>
                        <Button
                            className='submit-button'
                            type="submit"
                            onClick={(e) => this.handleSubmit(e)}
                        >
                            Submit
                            </Button>
                    </div>
                </div>
            </div>
        </Container>)
    }

    render() {
        return (
            <div className='add-service-page service'>
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>Add Service</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="add-service-body">
                    {this.state.loading ?
                        <this.Submitting /> : <this.UploadService />}
                    <br />
                </Container>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadImages: (images, location, cb) => dispatch(uploadImages(images, location, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddService)