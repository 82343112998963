
import axios from 'axios'

/**
 * service Object Constructor
 * @prop {String} address the street address of the service
 * @prop {String} city the city where the service is located
 * @prop {String} state the state where the service is located
 * @prop {Number} zip the service zip code
 * @prop {String} country the country where the service is located
 */
export function ServiceLocation(address, city, state, zip, country, coded) {
    this.address = address
    this.city = city
    this.state = state
    this.zip = zip
    this.country = country
    this.coded = coded;
}

/**
 * service Object Constructor
 * @prop {String} address the street address of the service
 * @prop {String} city the city where the service is located
 * @prop {String} state the state where the service is located
 * @prop {Number} zip the service zip code
 * @prop {String} country the country where the service is located
 */
export function Provider(user) {
    this.name = user.full_name ? user.full_name : null;
    this.user_id = user.user_id ? user.user_id : null;
    this.avatar_url = user.avatar_url ? user.avatar_url : null;
    this.phone_number = user.phone_number ? user.phone_number : null;
    this.email = user.email ? user.email : null;
    this.company = user.company_name ? user.company_name : null;
}
/**
 * service Object Constructor
 * @prop {String} address the street address of the service
 * @prop {String} city the city where the service is located
 * @prop {String} state the state where the service is located
 * @prop {Number} zip the service zip code
 * @prop {String} country the country where the service is located
 */
export function Requestor(user) {
    this.name = user.full_name ? user.full_name : null;
    this.id = user.user_id ? user.user_id : null;
    this.avatar_url = user.avatar_url ? user.avatar_url : null;
    this.phone_number = user.phone_number ? user.phone_number : null;
    this.email = user.email ? user.email : null;
}

export function getUserServices(params, cb) {
    axios.get('/api/services/user-services', { params: { ...params } }).then(res => {
        if (res.data) {
            cb(res.data)
        } else {
            cb({ success: false, msg: 'failed to get user services' })
        }
    }).catch(error => [
        cb({ success: false, msg: String(error) })
    ])
}

export function deleteService(id, cb) {
    var body = {
        id: id
    }
    axios.post('/api/services/delete', JSON.stringify(body)).then(res => {
        if (res.data) {
            cb(res.data)
        } else {
            cb({ success: false, msg: 'failed to delete services' })
        }
    }).catch(error => [
        cb({ success: false, msg: String(error) })
    ])
}

var offeredDefaults = {
    name: null,
    id: null,
}


export class OfferedService {

    constructor(props) {
        this.name = props.name ? props.name : null;
        this.id = props.id ? props.id : null;
        this.type = props.type ? props.type : null;
        this.provider = props.provider ? props.provider : null;
        this.catagory = props.catagory ? props.catagory : null;
        this.subCatagory = props.subCatagory ? props.subCatagory : null;
        this.price = props.price ? props.price : null;
        this.location = props.location ? props.location : null;
        this.description = props.description ? props.description : null;
        this.created = props.created ? props.created : null;
        this.updated = props.updated ? props.updated : null;
        this.image = props.image ? props.image : null;
    }

    updatedFields = {}

    setName = (name) => {
        this.name = name;
        if (this.id && this.created)
            this.updatedFields['name'] = name;
    }

    setId = (id) => {
        this.id = id;
        if (this.id && this.created)
            this.updatedFields['id'] = id;
    }

    setType = (type) => {
        this.type = type;
        if (this.id && this.created)
            this.updatedFields['type'] = type;
    }

    setProvider = (provider) => {
        this.provider = provider;
        if (this.id && this.created)
            this.updatedFields['provider'] = provider;
    }

    setCatagory = (catagory) => {
        this.catagory = catagory;
        if (this.id && this.created)
            this.updatedFields['catagory'] = catagory;
    }

    setSubCatagory(subCatagory) {
        this.subCatagory = subCatagory;
        if (this.id && this.created)
            this.updatedFields['subCatagory'] = subCatagory;
    }

    setPrice(price) {
        this.price = price;
        if (this.id && this.created)
            this.updatedFields['price'] = price
    }

    setLocation(location) {
        this.location = location
        if (this.id && this.created)
            this.updatedFields['location'] = location
    }

    setDescription(description) {
        this.description = description;
        if (this.id && this.created)
            this.updatedFields['description'] = description;
    }

    setImage(image) {
        this.image = image;
        if (this.id && this.created)
            this.updatedFields['image'] = image;
    }

    set(type, value) {
        this[type] = value;
        if (this.id && this.created)
            this.updatedFields[type] = value;
    }

    getName = () => {
        return this.name;
    }

    getId = () => {
        return this.id;
    }

    getType = () => {
        return this.type;
    }

    getProvider = () => {
        return this.provider;
    }

    getCatagory = () => {
        return this.catagory;
    }

    getSubCatagory() {
        return this.subCatagory;
    }

    getPrice() {
        return this.price;
    }

    getLocation() {
        return this.location;
    }

    getDescription() {
        return this.description;
    }

    getProvider() {
        return this.provider;
    }

    getCreated() {
        return this.created;
    }

    getUpdated() {
        return this.updated;
    }

    getImage() {
        return this.image;
    }

    submit(type, cb) {
        var tmp = this;
        delete tmp['updatedFields']
        var body = {
            service: JSON.parse(JSON.stringify(tmp)),
            type: type
        }
        axios.post(`/api/services/submit`, JSON.stringify(body)).then(res => {
            if (res.data) {
                console.log("res from axios", res)
                cb(res.data)
            } else {
                cb({ success: false, msg: String(res.error) })
            }
        }).catch(error => {
            cb({ success: false, msg: String(error) })
        });
    }

    update(type, cb) {
        var body = {
            type: type,
            id: this.id,
            fields: this.updatedFields
        }
        axios.post(`/api/services/update`, JSON.stringify(body)).then(res => {
            if (res.data) {
                console.log("res from axios", res)
                cb(res.data)
            } else {
                cb({ success: false, msg: String(res.error) })
            }
        }).catch(error => {
            cb({ success: false, msg: String(error) })
        });

    }

    setImage(image) {
        this.image = image
        if (this.id && this.created) {
            this.updatedFields['image'] = image;
        }
    }

    removeImage() {
        this.image = null
    }

    delete(cb) {
        var body = {
            id: this.id
        }
        axios.post('/api/services/delete', JSON.stringify(body)).then(res => {
            if (res.data) {
                cb(res.data)
            } else {
                cb({ success: false, msg: 'failed to delete services' })
            }
        }).catch(error => [
            cb({ success: false, msg: String(error) })
        ])
    }

    load(id, cb) {
        axios.get('/api/services/service', { params: { id: id, type: "offered" } }).then(res => {
            console.log("data", res.data)
            if (res.data) {
                Object.keys(res.data.msg).map((key, idx) => {
                    this[key] = res.data.msg[key]
                    if (idx == Object.keys(res.data.msg).length - 1) {
                        cb({ success: true, msg: this })
                    }
                })
            } else {
                cb({ success: false, msg: 'failed to set service' })
            }
        }).catch(error => [
            cb({ success: false, msg: String(error) })
        ])
    }


}

class RequestedService extends OfferedService {
    constructor(props) {
        super(props);
        this.requestor = props.requestor ? props.requestor : null;
        this.message = props.message ? props.message : null;
        this.status = props.status ? props.status : null;
        this.requested = props.requested ? props.requested : null;
        this.accepted = props.accepted ? props.accepted : null;
        this.rejected = props.rejected ? props.rejected : null;
    }

    getRequestor() {
        return this.requestor;
    }

    getMessage() {
        return this.message;
    }

    getStatus() {
        return this.status;
    }

    getRequested() {
        return this.requested;
    }

    getApproved() {
        return this.approved;
    }

    getAccepted() {
        return this.accepted;
    }

    getRejected() {
        return this.rejected;
    }

    setRequestor(requestor) {
        this.requestor = requestor;
    }

    setMessage(message) {
        this.message = message;
    }

    setStatus(status) {
        this.status = status;
    }

    setRequested(requested) {
        this.requested = requested;
    }

    setAccepted(accepted) {
        this.accepted = accepted;
    }

    setRejected(rejected) {
        this.rejected = rejected;
    }

    submit(cb) {
        super.submit(res => {
            cb(res)
        })
    }

}

// module.exports = {
//     RequestedService,
//     ServiceLocation,
//     Provider,
//     Requestor
// }
