import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider, useSelector } from "react-redux";
import thunk from "redux-thunk";
import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import {
  ReactReduxFirebaseProvider,
  getFirebase,
  isLoaded,
} from "react-redux-firebase";
import fbConfig from "./config/fbConfig";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "font-awesome/css/font-awesome.min.css";
import "./styles.css";
import MainSpinner from "./Spinners/MainSpinner";
import axios from "axios";

// https://us-central1-investfar-b494a.cloudfunctions.net/app

//  axios.defaults.baseURL = 'http://localhost:5001/investfar-3d385/us-central1/app';
// axios.defaults.baseURL = ' https://us-central1-investfar-b494a.cloudfunctions.net/app';

//  axios.defaults.baseURL = 'https://investfar.com';
axios.defaults.baseURL =
  "https://us-central1-investfar-b494a.cloudfunctions.net/app";
//  axios.defaults.baseURL = 'http://localhost:5000/investfar-b494a/us-central1/app';
//  axios.defaults.baseURL = 'http://localhost:5001/investfar-b494a/us-central1/app';

axios.defaults.headers = { "Content-type": "application/json" };
const getDatabase = () => fbConfig.firestore();
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(
      thunk.withExtraArgument({ getFirestore, getFirebase, getDatabase })
    ),
    reduxFirestore(firebase, fbConfig)
  )
);

const rrfConfig = {
  userProfile: "Users",
  useFirestoreForProfile: true,
};

const fbProps = {
  firebase,
  config: rrfConfig,
  fbConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <div className="loader">
        <MainSpinner />
      </div>
    );
  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...fbProps}>
        <AuthIsLoaded>
          <App />
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
