import React from 'react';
import { InputGroup, Form, FormControl, Dropdown, DropdownButton, Button, Row, Col, Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class LocalConsultantRegister extends React.Component {

    localregister = () => {
        return (
            <div className="consult-page">
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>Registration</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="consult-account">
                    <Row>
                        <Card className="consult-register-card">
                            <Form className="register-form">
                                <h2 style={{ marginTop: '40px' }} >Agent Registration</h2>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control placeholder="Name" />
                                </Form.Group>
                                <Form.Row>
                                    <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={6}>
                                        <Form.Label>Website URL</Form.Label>
                                        <Form.Control placeholder="Website URL" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={6}>
                                        <Form.Label>Select Country</Form.Label>
                                        <Form.Control as="select" defaultValue="Country Code">
                                            <option>Country Code</option>
                                            <option>...</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6}>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control placeholder="Phone Number" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={6}>
                                        <Form.Label>Primary Address</Form.Label>
                                        <Form.Control type="address" placeholder="Primary Business Address" />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6}>
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control placeholder="0" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" />
                                    </Form.Group>
                                <div className="checkboxes">
                                    <Form.Row>
                                        <Form.Group>
                                            <Form.Check name="wealso" type="checkbox" label="We also operate in additional states" />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group>
                                            <Form.Check name="areyou" type="checkbox" label="Are you a licensed agent?" />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group>
                                            <Form.Check name="otherlicense" type="checkbox" label="Other Licenses" />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group>
                                            <Form.Check name="brokerad" type="checkbox" label="Broker Address" />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group>
                                            <Form.Check name="website" type="checkbox" label="Website" />
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                                <Form.Group as={Row}>
                                    <Col><Button className="sign-up-button" type="submit">Sign Up</Button></Col>
                                </Form.Group>
                            </Form>
                        </Card>
                    </Row>
                </div> 
            </div>
        )
    }

    render() {
        require('./LocalConsultantRegister.css')
        return (
            <this.localregister />
        )
    }
}

export default LocalConsultantRegister