import React from 'react';
import { InputGroup, Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

class About extends React.Component {

    aboutus = () => {
        return (
            <div className="about-page">
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>ABOUT US</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="about-body">
                    <Row>
                        <Col sm={4}>
                                <iframe className="about-iframe" src="https://player.vimeo.com/video/246744111" width="340" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                        </Col>
                        <Col><Card className="about-text">
                            <p1>
                                InvestFar is the leading real estate investor marketplace and database specifically dedicated to empowering real estate investors with data, resources and knowledge to instill confidence in investing in any market nationwide, by connecting them with the best local investor consultants and tools to make informed buying decisions.
                            <br /><br />
                            InvestFar serves single/multi-family investors to effectively grow and manage real estate portfolios from buying, selling, renting, financing, and remodeling in any market nationwide. InvestFar’s database includes homes for sale, rent and homes not currently on the market, as well as provides other investment related information like valuation reports, market data and area analytics.
                            <br /><br />
                            InvestFar also operates the most popular mobile real estate investor app for home improvement services along with a host of private lenders and funding options with a free marketplace to list once rehabbed.
                            <br /><br />
                            Companies and contractors can also schedule, cancel, book and review quote request on the app to manage clients on the go.
                            <br /><br />
                            Not familiar with a certain market and are interested in buying there? Speak with an investor consultant in that market as well.
                            <br /><br />
                            InvestFar launched in 2017 and is headquartered in Los Angeles, CA.
                            </p1>
                            <br />
                        </Card></Col>
                    </Row>
                </Container>
            </div>
        )
    }

    render() {
        require('./About.css')
        return (
            <this.aboutus />
        )
    }
}

export default About