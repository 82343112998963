import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Container,
    Button,
    Card,
    Badge,
} from 'react-bootstrap'
import {
    BsTrash,
    BsPencilSquare,
} from 'react-icons/bs'
import { connect } from 'react-redux';
import './Notifications.css';
import firebase from '../config/fbConfig.js'; 

function Notifications(props) {

    const [user, setUser] = useState(); 
    const [notifications, setNotifications] = useState(
        [
            {
                "name": "test",
                "number": 1,
                "time": "t"
            },
            {
                "name": "hoe",
                "number": 2,
                "time": "t"
            },
            {
                "name": "test",
                "number": 1,
                "time": "t"
            },   
        ]
    );


    useEffect(() => {
        window.scrollTo(0, 0)
        const { authSuccess } = props;
        const { user, profile } = props;
        console.log('Current User', profile)
        var params = {
            type: 'offered',
            account: 'provider',
            id: profile.user_id,
        }
        var userEmail = props.auth.email; 
        setUser(userEmail.substring(0, userEmail.indexOf('.'))); 
        console.log(firebase); 
        const messaging = firebase.messaging(); 

    }, [])

    return (
        <div className="valuation-page">
            <Container fluid className="jumbo">
                <Row>
                    <Col sm={5}>
                        <div className="jumbo-content-left">
                            <h2>Notifications Test (REMOVE FROM PRD)</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="notify-body">
                <Card className="notify-card">
                    {
                        notifications.map((item) => {
                            return (
                                <Badge className="notify-badge" variant="light">
                                    <Row className="badge-content">
                                        <Col><Badge>{item.number}</Badge></Col>
                                        <Col>{item.name}</Col>
                                        <Col>{user}</Col>
                                        <Col>{item.time}</Col>
                                    </Row>
                                </Badge>
                            )
                        })
                    }
                </Card>
            </Container>
        </div>
    )
}


const mapStateToProps = (state) => {
    console.log(state)
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default connect(mapStateToProps)(Notifications)