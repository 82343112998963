import React, { useState } from 'react';
import { Form, FormControl, Dropdown, DropdownButton, Card, Button, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import opportunity_graph from './images/graph.png';
import axios from 'axios';
import { Error, Primary, Success, Info, Warning } from './../Alerts'


class OpportunityZones extends React.Component {
    state = {
        initialgain: 25000,
        expectedreturn: .06,
        firstname: '',
        lastname: '',
        phonenumber: '',
        email: '',
        gainoptions: [
            { value: '25000', label: '$25,000', name: 'initialgain' },
            { value: '50000', label: '$50,000', name: 'initialgain' },
            { value: '100000', label: '$100,000', name: 'initialgain' },
            { value: '250000', label: '$250,000', name: 'initialgain' },
            { value: '500000', label: '$500,000', name: 'initialgain' },
            { value: '1000000', label: '$1,000,000', name: 'initialgain' }
        ],
        returnoptions: [
            { value: '.01', label: '1%', name: 'expectedreturn' },
            { value: '.02', label: '2%', name: 'expectedreturn' },
            { value: '.03', label: '3%', name: 'expectedreturn' },
            { value: '.04', label: '4%', name: 'expectedreturn' },
            { value: '.05', label: '5%', name: 'expectedreturn' },
            { value: '.06', label: '6%', name: 'expectedreturn' },
            { value: '.07', label: '7%', name: 'expectedreturn' },
            { value: '.08', label: '8%', name: 'expectedreturn' },
            { value: '.09', label: '9%', name: 'expectedreturn' },
            { value: '.10', label: '10%', name: 'expectedreturn' },
            { value: '.11', label: '11%', name: 'expectedreturn' },
            { value: '.12', label: '12%', name: 'expectedreturn' },
        ],
        investorcheck: false,
        traditionalreturns: '',
        primiorreturns: '',
    }

    inputHandler = (event) => {

        this.setState({
            [event.name]: event.value
        })
    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleOptionChange = (event) => {
        console.log(event.target.value)
        this.setState({
            investorcheck: event.target.value
        })
    }

    submitRequest = () => {
        var toSend = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            phonenumber: this.state.phonenumber,
            email: this.state.email,
            investor: this.state.investorcheck,
        }

        axios.post('/api/services/send-opportunity-request', toSend)
            .then(response => {
                if (response) {
                    console.log(response);
                    Info({ title: 'Message Delivered', message: 'A representative will be in contact with you shortly! ' })
                }
            }).catch(error => {
                console.log(error);
                Error({ title: 'Error Encountered', message: 'Error encountered when sending contact email!' })
            });
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    calculateReturns = () => {
        var traditionalObject, primiorObject;
        var years = [5, 7, 10];
        var traditionalFormula = this.state.initialgain - (1 - Math.pow(5, .06))
        years.forEach(year => {
            traditionalObject.push(this.state.initialgain - (1 - Math.pow(year, .06)));
            primiorObject.push(this.state.initialgain - (2 - Math.pow(year, .06)));
        })

        this.setState({
            traditionalreturns: traditionalObject,
            primiorreturns: primiorObject
        })

    }

    investorGraph = () => {
        const data = {
            labels: ['Hold for 5 years', 'Hold for 7 years', 'Hold for 10 years'],
            datasets: [
                {
                    label: 'Traditional stock portfolio',
                    backgroundColor: [
                        '#404040',
                        '#404040',
                        '#404040',
                    ],
                    data: [6500, 5900, 8000]
                    // data: state.traditionalreturns
                },
                {
                    label: 'Primior Opportunity Fund',
                    backgroundColor: [
                        'rgb(35,119,186)',
                        'rgb(35,119,186)',
                        'rgb(35,119,186)',
                    ],
                    data: [1500, 2900, 3000]
                }
            ]
        }
        return (
            <Bar
                data={data}
                width={100}
                height={250}
                options={{ maintainAspectRatio: false }}
            />
        )
    }

    render() {
        require('./Opportunity.css')
        // var opp = () => {
        return (
            <div className="opp-page">
                <Container fluid className="opp-jumbo">
                    <Row>
                        <Container fluid className="jumbo-content-center">
                            <h2 className="jumbo-header-text">Opportunity Zones</h2>
                            <h5 className="jumbo-header-subtext">Reduce taxes on your capital gains by investing in a new era of real estate opportunities.</h5>
                            <Link to='./pricing'>
                                <Button className="jumbo-header-button" >Become An Investor</Button>
                            </Link>
                        </Container>
                    </Row>
                </Container>
                <Container fluid className="opp-body">
                    <Card className="graph-card">
                        <img className="graph-img" src={opportunity_graph} />
                    </Card>
                    <Row>

                        <div className="col-content">
                            <h4>What Is an Opportunity Zone?</h4>
                            <p1>The Opportunity Zone program was created by the 2017 Tax Cuts & Jobs Act to revitalize economically distressed communities through private investment as opposed to taxpayer dollars. Opportunity Zones have now been designated in all 50 U.S. states, the District of Columbia and five U.S. possessions.</p1>
                        </div>
                        <div className="col-content">
                            <h4>How Does The Process Work?</h4>
                            <p1>Anyone qualifying to participate in this program can invest in an Opportunity Fund and gain the associated tax advantages. This type of investment can also be advantageous for reinvesting a capital gain from selling stock or real estate, for example.</p1>
                        </div>
                        <div className="col-content">
                            <h4>Why Invest in an Opportunity Fund?</h4>
                            <p1>Taxpayers investing in a Qualified Opportunity Zone via an Opportunity Fund can benefit from capital gains tax incentives available exclusively through the program. Moreover, in comparison to other programs that encourage private investment in low-income areas through tax advantages, the Opportunity Zone program is less restrictive, costly or reliant upon government agencies.</p1>
                        </div>
                        <div className="whychoose-content">
                            <h4>Why choose Opportunity Zones?</h4>
                            <p1 className="whychoose-text">The economic disparity between O-Zones and other areas highlights the need for thoughtful market selection. We’ve ranked the long-term growth potential of each O-Zone by combining data-driven spotlighting tools with on-the-ground developer relationships and market evaluations.</p1>
                            <Container fluid className="opp-images-parent">
                                <div className="map-img"></div>
                                <div className="map-point-img"></div>
                            </Container>
                            <Row>
                                <Card className="whychoose-card">
                                    <hr /><h1 className="whychoose-subtext">8,700</h1><p1>Total Number of O-Zones</p1>
                                </Card>
                                <Card className="whychoose-card">
                                    <hr /><h1 className="whychoose-subtext">11%</h1><p1>Share of the U.S. Population</p1>
                                </Card>
                                <Card className="whychoose-card">
                                    <hr /><h1 className="whychoose-subtext">~ 41%</h1><p1>Lower Incomes vs. Non-Ozones</p1>
                                </Card>
                                <Card className="whychoose-card">
                                    <hr /><h1 className="whychoose-subtext">~ 38%</h1><p1>Lower Homeownership Rate vs. Non-Ozones</p1>
                                </Card>
                            </Row>
                            <br /><p1>Sources: U.S. Census, STI Popstats</p1><br /><hr />
                        </div>
                        <Container fluid className="calculate-tax-content">
                            <h4>Calculate your potential after-tax returns</h4>
                            <p1>Use our calculator to estimate how much money you could potentially save on a capital gain by investing through an Opportunity Fund versus a standard stock portfolio.</p1>
                            <div className="return-calculate-content">
                                <Row className="calculator-inputs">
                                    <Col sm={5}>
                                        <h6>Initial gain to invest</h6>
                                        <Select name="initialgain" className="dropdown-input" defaultValue={this.state.gainoptions[0]} options={this.state.gainoptions}
                                            onChange={(event) => { this.inputHandler(event) }}
                                        /></Col>
                                    <Col sm={5}>
                                        <h6>Expected annual return</h6>
                                        <Select name="expectedreturn" className="dropdown-input" defaultValue={this.state.returnoptions[5]} options={this.state.returnoptions}
                                            onChange={(event) => { this.inputHandler(event) }}
                                        /></Col>
                                </Row></div>
                            <div className="return-graph"><investorGraph /></div>
                        </Container>
                        <Container fluid className="request-card">
                            <div className="request-content">
                                <br />
                                <h5 className="request-text">Request Information</h5>
                                <p1 className="request-text">We are highly selective in developing high-quality real estate projects with growth potential over the long term. Our belief is that Opportunity Funds offer significant benefits to both the intelligent investor and the communities classified as Opportunity Zone.</p1>
                                <div className="request-form">
                                    <Form>
                                        <Row className="form-row">
                                            <Col sm={6}>
                                                <Form.Control name="firstname" onChange={this.changeHandler} placeholder="First name" />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Control name="lastname" onChange={this.changeHandler} placeholder="Last name" />
                                            </Col>
                                        </Row>
                                        <Row className="form-row">
                                            <Col sm={6}>
                                                <Form.Control name="email" onChange={this.changeHandler} type="email" placeholder="Email" />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Control name="phonenumber" onChange={this.changeHandler} placeholder="Phone Number" />
                                            </Col>
                                        </Row>
                                        <h6 className="request-text">Are you an accredited investor?</h6>
                                        <Row className="radio-row">
                                            <div className="radio"><label>
                                                <input type="radio" value="Yes" checked={this.state.investorcheck === "Yes"}
                                                    onChange={this.handleOptionChange} />Yes</label></div>
                                            <div className="radio"><label>
                                                <input type="radio" value="No" checked={this.state.investorcheck === "No"} onChange={this.handleOptionChange} />No</label></div>
                                        </Row>
                                        <Button className="submit-button-op" onClick={() => this.submitRequest()}>Submit</Button>
                                    </Form>
                                </div>
                            </div>
                        </Container>
                        <Container fluid className="additional">
                            <h5 className="additional-text">Have Additional Questions?</h5>
                            <div className="book-card" onClick={() => { this.openInNewTab('https://www.investfar.com/assets/Guide_to_investing_in_realestate_in_OpportunityZones.pdf') }}>
                                <div className="book-img"></div>
                                <h6 className="book-text">Download</h6>
                            </div>
                            <br />
                            <h5 className="additional-text">Or contact us directly at +1 (310) 248-0744 and info@investfar.com.</h5>
                        </Container>
                    </Row>
                </Container>

            </div>
        )
        // }

        // render() {
        // require('./Opportunity.css')
        // return (
        //     <opp />
        // )
        // }
    }
}

export default OpportunityZones