import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Container,
    Button,
    Spinner,
} from 'react-bootstrap'
import '../../Property/MyProperties/MyProperties.css'
import {
    BsTrash,
    BsPencilSquare,
} from 'react-icons/bs'
import { Error, Warning, Primary, Info, Success } from '../../Alerts'
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import Far from '../../Spinners/Far'
import axios from 'axios';
import {
    OfferedService,
    RequestedService,
    ServiceLocation,
    Provider,
    Requestor,
    getUserServices,
    deleteService
} from '../../Constructors/Service'

function MyOfferedServices(props) {
    const [services, setServices] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [current, setCurrent] = useState({})
    const [deleteService, setDeleteService] = useState(false)
    const [deleted, setDeleted] = useState(null)
    const [loading, setLoading] = useState(true)
    const [path, setPath] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        const { authSuccess } = props;
        const { user, profile } = props;
        console.log('Current User', profile)
        var params = {
            type: 'offered',
            account: 'provider',
            id: profile.user_id,
        }

        getUserServices(params, res => {
            if (res.success) {
                setServices(res.msg)
            } else {
                Error({ title: 'Failed', message: res.msg })
                setServices([])
            }
            setLoading(false)
        })

    }, [])

    var handleRedirect = (current, path) => {
        setRedirect(true)
        setCurrent(current)
        setPath(path)
        console.log('Redirecting to edit', current)
    }

    var DeletingProperty = () => {
        return (
            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <Container>
                    <h4>Deleting Service...</h4>
                    <p></p>
                    <p></p>
                    <Spinner animation='border' variant='primary' />
                    <p></p>
                    <p></p>
                </Container>

            </div>
        )

    }

    var RenderRedirect = () => {
        if (redirect) {
            return <Redirect
                to={{
                    pathname: path,
                    state: { currentService: current },
                    serviceProps: current.name,
                    currentService: current,
                }}
            />
        } else {
            return (<></>)
        }
    }

    var Service = (props) => {
        var onDelete = (current) => {
            var that = this
            console.log('property deleted')
            setDeleteService(true)
            current.delete(res => {
                if (res.data) {
                    setDeleted(res.data.msg)
                    if (res.data.success) {
                        var tmp = services;
                        setServices(tmp.filter(el => el !== current))
                        console.log('Service Deleted')
                    }
                    setDeleteService(false)
                } else {
                    setDeleted({ success: false, msg: 'Failed to delete service' })
                    setDeleteService(false)
                }
            }).catch(error => {
                setDeleted({ success: false, msg: error.toString() })
                setDeleteService(false)
            })

        }
        var unit = props.current.type == "hourly" ? "/hr" : "";
        var price = `$${Number.parseFloat(props.current.price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${unit}`
        var background
        if (props.index % 2 == 0) {
            background = 'rgba(209, 209, 218, 0.2)'
        } else {
            background = '#fff'
        }
        return (
            <tr className='proptables-row' style={{ backgroundColor: background }}>
                <td><div className='header-elem' style={{ margin: '20px' }}>{props.index}</div></td>
                <td style={{ cursor: "pointer" }}>
                    <img
                        onClick={() => handleRedirect(props.current, '/service')}
                        className='header-elem' style={{ width: '150px' }} src={props.current.image} />
                </td>
                <td><div className='header-elem'>{props.current.name}</div></td>
                <td><div className='header-elem'>{props.current.location.city + ', ' + props.current.location.state}</div></td>
                <th><div className='header-elem'>{price}</div></th>
                <th><div className='header-elem'>{props.current.catagory}</div></th>
                <th><div className='header-elem'>{props.current.subCatagory}</div></th>
                <td><div className='header-elem'><Button
                    onClick={() => handleRedirect(props.current, '/edit-service')}
                    style={{ borderRadius: '100%' }} variant='info' size='sm'><BsPencilSquare /></Button></div></td>
                <td><div className='header-elem'>
                    {deleteService ?
                        (
                            <div> Deleting... <Spinner animation='border' variant='danger' style={{ width: '20px', height: '20px' }} /></div>
                        )
                        :
                        (
                            <Button
                                onClick={() => onDelete(new OfferedService(props.current))}
                                style={{ borderRadius: '100%' }} variant='danger' size='sm'>
                                <BsTrash />
                            </Button>
                        )
                    }
                </div></td>
            </tr >
        )
    }
    return (
        <div className='submit-property-page property'>
            <Container fluid className="jumbo">
                <Row>
                    <Col sm={5}>
                        <div className="jumbo-content-left">
                            <h2>My Services</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-properties-body">
                {loading ?
                    (<div style={{ textAlign: 'center' }}>
                        <h5 className='loading'>Loading</h5>
                        <div style={{ height: '20px' }} />
                        {/* <Spinner variant='primary' animation='border' /> */}
                        {/* <Invest/> */}
                        <Far />
                        <div style={{ height: '100px' }} />

                    </div>
                    )
                    :
                    (
                        <div>
                            {
                                (!services || services.length < 1 && !loading) ?
                                    (
                                        <Container>
                                            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                                <Container>
                                                    <h4>NO Services</h4>
                                                    <p></p>
                                                    <p></p>
                                                    <p>No Services found</p>                                                <p></p>
                                                    <p></p>
                                                </Container>

                                            </div>
                                        </Container>
                                    ) :
                                    (
                                        <table className='proptables'>
                                            <tbody>
                                                <tr className='property-table-header'>
                                                    <th><div className='header-elem'>#</div></th>
                                                    <th><div className='header-elem'>Image</div></th>
                                                    <th><div className='header-elem'>Name</div></th>
                                                    <th><div className='header-elem'>Location</div></th>
                                                    <th><div className='header-elem'>Price</div></th>
                                                    <th><div className='header-elem'>Catagory</div></th>
                                                    <th><div className='header-elem'>Sub Catagory</div></th>
                                                    <th><div className='header-elem'>Edit</div></th>
                                                    <th><div className='header-elem'>Delete</div></th>
                                                </tr>
                                                {services.map((currentService, key) => {
                                                    return (
                                                        <Service index={key + 1} current={currentService} />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    )
                            }
                        </div>

                    )
                }
                <RenderRedirect />
                <div>
                </div>
                <br />
            </Container>
        </div>
    )
}


const mapStateToProps = (state) => {
    console.log(state)
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default connect(mapStateToProps)(MyOfferedServices)