const { v4: uuidv4 } = require('uuid')

/**
 * User Object Constructor
 * @prop {String} user_name: the user name the user picked
 * @prop {String} user_id the user id
 * @prop {String} avatar_url: the profile picture url
 * @prop {String} service_key: the service key of the user
 * @prop {String} first_name: the first name of the user
 * @prop {String} last_name: the last name of the user
 * @prop {String} email: the email address of the user
 * @prop {String} company_name: the company of the user
 * @prop {String} registration_type the regitration type of the user
 * @prop {String} phone_number: the phone number of the user
 * @prop {String} latitude: the geographical latitude 
 * @prop {String} longitude: the geographical longitude
 * @prop {String} company_website: the company website for the user
 * @prop {String} address the address of the user
 * @prop {String} city: the city for the user
 * @prop {String} state: the state the user is located
 * @prop {String} country the country the user is located in
 * @prop {String} zipcode: the zip code for the user
 * @prop {String} shieldpay_customer_key: the shield pay customer key
 * @prop {String} super_admin_email: the email for the super admin
 */
function User(user_name, avatar_url, service_key, first_name,
    last_name, full_name, email, company_name, registration_type, phone_number,
    latitude, longitude, company_website, address, city, state, country, zipcode,
    shieldpay_customer_key, super_admin_email, user_id) {
    this.user_name = user_name
    this.avatar_url = avatar_url
    this.service_key = service_key
    this.first_name = first_name
    this.last_name = last_name
    this.full_name = full_name
    this.email = email
    this.company_name = company_name
    this.registration_type = registration_type
    this.phone_number = phone_number
    this.latitude = latitude
    this.longitude = longitude
    this.company_website = company_website
    this.address = address
    this.city = city
    this.state = state
    this.country = country
    this.zipcode = zipcode
    this.shieldpay_customer_key = shieldpay_customer_key
    this.super_admin_email = super_admin_email
    this.user_id = user_id
}

// const CURRENT = Object.freeze({
//     user: new User('rushwell', '100', 'https://t4.ftcdn.net/jpg/03/32/59/65/360_F_332596535_lAdLhf6KzbW6PWXBWeIFTovTii1drkbT.jpg'
//         , 'test', 'Rushwell', 'Davetero', 'rushwell.dzvetero@gmail.com',
//         'KairosVR', 'member', '9728206301', '13.34343', '34.34245', 'null', '456 SW Chunindran Katagan', 'Butiwhol', 'Punjabi', 'India',
//         '57483', '49584', 'niggas@newone.com', '100'
//     )
// })

// console.log(JSON.stringify(CURRENT))

module.exports = {
    User,
    // CURRENT
}

