import React from 'react'
import {
    OfferedService,
    RequestedService,
    ServiceLocation,
    Provider,
    Requestor
} from '../../Constructors/Service'
import {
    Row,
    Col,
    Container,
    Form,
    Collapse,
    Button
} from 'react-bootstrap'
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import AutoComplete from '../../AutoComplete/AutoComplete'
import Far from '../../Spinners/Far'
import { Error, Primary, Success, Info, Warning } from '../../Alerts'
import { TiDeleteOutline } from 'react-icons/ti'
import { uploadImages } from '../../store/actions/userActions';
import toCurrency from '../../Generic/Functions/toCurrency'



require('../AddService/AddService.css')


class EditService extends React.Component {

    state = {
        redirect: false,
        target: null,
        service: null,
        profile: null,
        openSubmit: false,
        location: null,
        loading: true,
        image: null,
        imageData: null,
        isEditing: false,
        catagories: {
            "Select Catagory": [
                "select Sub Catagory"
            ],
            "General Contractor": [
                "Kitchen Remodel",
                "Custom Home Build",
                "Bathroom Remodel",
                "Roof Repair",
                "Pool Installation",
                "Home Improvement",
                "Outdoor Landscaping",
                "Home Security/Alarm Installation"
            ],

            "Cleaning": [
                "Bathroom Cleaning",
                "Carpet Cleaning",
                "Floor CLeaning",
                "Home Cleaning",
                "Kitchen Cleaning",
                "Sofa Cleaning",
                "Pressure Washing",
                "Gutter Cleaning"
            ],
            "Lending": [
                "Construction",
                "Rental Loans",
                "Fix and Flip Loans",
                "Home Purchase",
                "Land Purchase",
                "Short-Tearm Loans",
                "Cash Purchase",
                "Fundraising"
            ],
            "Plumbing": [
                "Back Flow Prevention",
                "Bathtubs & Shower",
                "Drain Cleaning",
                "Gas Piping",
                "Leaky Toilet",
                "Slab Leak Detection",
                "Water Heater",
                "Water Line Repair"
            ],
            "Property Management": [
                "Property Management",
                "Canada Property Management"
            ]
        }
    }
    componentDidMount() {
        const { profile, user } = this.props;
        this.setState({
            profile: profile
        })
        var service = new OfferedService({})
        var id = this.props.location.state.currentService.id;
        if (id && id != null && id != 'undefined') {
            service.load(this.props.location.state.currentService.id, res => {
                this.setState({
                    loading: false
                })
                if (res.success) {
                    this.setState({
                        service: service
                    })
                } else {
                    this.setState({
                        service: null
                    })
                    Error({ title: 'Failed', message: 'Failed to load service' })
                }
            })
        }


    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.target} />
        }
    }

    handleType = (event) => {
        var value = event.target.checked
        var type = event.target.id
        var serviceValue = null
        switch (type) {
            case 'fixedCost':
                serviceValue = value ? 'fixedCost' : 'hourly'
                break;
            case 'hourly':
                serviceValue = value ? 'hourly' : 'fixedCost'
                break;
            default:
                serviceValue = null;
                break;
        }

        this.handleFieldChange("type", serviceValue)
    }

    handleFieldChange = (field, value) => {
        var serviceState = this.state.service ? this.state.service :
            new OfferedService({ provider: new Provider(this.state.profile), id: "test", created: "test" })
        serviceState.set(field, value)
        this.setState({ service: serviceState })
    };

    removeImage = () => {
        this.setState({
            image: null,
            imageData: null
        })
    }

    removeImageCurrent = () => {
        var service = this.state.service
        service.removeImage()
        this.setState({
            service: service
        })
    }

    imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({ image: reader.result })
            }
        }
        reader.readAsDataURL(e.target.files[0])
        this.setState({ imageData: e.target.files[0] })
    };

    submitImage = (e, cb) => {
        e.preventDefault();
        this.props.uploadImages([this.state.imageData], 'service_images', (response) => {
            cb(response)
        });
    }

    handleUpdate = (e) => {

        this.setState({
            loading: true
        })
        var service = this.state.service

        if (this.state.image && this.state.imageData) {
            this.submitImage(e, url => {
                if (url && Array.isArray(url) && url.length > 0 && url[0] !== 'undefined') {
                    service.setImage(url[0])
                    service.update("offered", res => {
                        this.setState({
                            openSubmit: false
                        })
                        if (res.success) {
                            this.setState({
                                redirect: true,
                                target: '/my-offered-services'
                            });
                            Success({ title: "Success", message: "Service Successfully updated" })
                        } else {
                            Error({ title: "Failed", message: "Service failed to upload" })
                        }
                        console.log("submit service response", res)
                    })
                }
            })
        } else {
            service.update("offered", res => {
                this.setState({
                    openSubmit: false
                })
                if (res.success) {
                    this.setState({
                        redirect: true,
                        target: '/my-offered-services'
                    });
                    Success({ title: "Success", message: "Service Successfully updated" })
                } else {
                    Error({ title: "Failed", message: "Service failed to upload" })
                }
                console.log("submit service response", res)
            })
        }

    }


    Submitting = () => {
        return (
            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <Container>
                    <div style={{ height: '50px' }} />
                    <h5 className='loading'>Updating Service...</h5>
                    <p></p>
                    <p></p>
                    {/* <Spinner animation='border' variant='primary' /> */}
                    {/* <Invest /> */}
                    <Far />
                    <p></p>
                    <p></p>
                    <div style={{ height: '100px' }} />
                </Container>

            </div>
        )
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }
    UploadService = () => {
        var setLocation = (loc) => {
            var service = this.state.service
            console.log("location", loc)
            service.setLocation(loc)
            this.setState({
                service: service
            })
        }


        console.log("type", this.state.service.getType())

        console.log("Stste", this.state)
        return (<Container>
            <div style={{ height: '40px' }} />
            <div className="add-service-card">
                <div className='add-service-header'>
                    <Row>
                        <Col>
                            <Row>
                                <h6>Edit Service</h6>
                            </Row>
                            <Row>
                                <div className='small-header-row-service'></div>
                            </Row>
                        </Col>
                    </Row>

                </div>
                <div className="service-content">
                    <Row>
                        <Col sm={2}>
                            <input className='custom-input' type="file" accept="image/*" id="profileChange" onChange={(e) => this.imageHandler(e)} />
                        </Col>
                        <Col>
                            <Row>
                                {this.state.image == null ?
                                    this.state.service && this.state.service.getImage() == null ? null
                                        : <Col sm={2} className="pic-holder">
                                            <div className='pic'>
                                                <div className='remove' onClick={() => this.removeImageCurrent()}>
                                                    <TiDeleteOutline className='icon' />
                                                </div>
                                                <img className="img-responsive" src={this.state.service ? this.state.service.getImage() : ""} alt="img" title="img" />
                                            </div>
                                        </Col>
                                    : <Col sm={2} className="pic-holder">
                                        <div className='pic'>
                                            <div className='remove' onClick={() => this.removeImage()}>
                                                <TiDeleteOutline className='icon' />
                                            </div>
                                            <img className="img-responsive" src={this.state.image} alt="img" title="img" />
                                        </div>
                                    </Col>
                                }


                            </Row>

                        </Col>
                    </Row>
                    <Form.Label>Service Type *</Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} sm={6} controlId="propertyType">
                            <Form.Check
                                checked={this.state.service.getType() == 'fixedCost'}
                                onChange={(event) => this.handleType(event)}
                                id='fixedCost'
                                type="checkbox"
                                label="Fixed Cost Service" />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="propertyType">
                            <Form.Check
                                checked={this.state.service.getType() == 'hourly'}
                                onChange={(event) => this.handleType(event)}
                                id='hourly'
                                type="checkbox"
                                disabled={this.state.anyType}
                                label="Hourly Based Service" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Service Name *</Form.Label>
                        <Form.Control className='form-input'
                            defaultValue={this.state.service ? this.state.service.name : null}
                            onChange={(event) => this.handleFieldChange('name', event.target.value)} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Service Catagory *</Form.Label>
                            <Form.Control as="select"
                                defaultValue={this.state.service.getCatagory()}
                                onChange={(event) => this.handleFieldChange('catagory', event.target.value)}>
                                {Object.keys(this.state.catagories).map(catagory => {
                                    return <option>{catagory}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Sub Catagory *</Form.Label>
                            <Form.Control as="select"
                                defaultValue={this.state.service.getSubCatagory()}
                                onChange={(event) => this.handleFieldChange('subCatagory', event.target.value)}>
                                {this.state.service.getCatagory() ?
                                    this.state.catagories[this.state.service.getCatagory()] ?
                                        this.state.catagories[this.state.service.getCatagory()].map(sub => {
                                            return <option>{sub}</option>
                                        }) : <option>Sub Catagory </option> : <option>Sub Catagory </option>}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    {/* <Form.Group>
                        <Form.Label>Location  *</Form.Label>
                        <Form.Control className='form-input' placeholder="Service Location"
                            onChange={(event) => this.handleFieldChange('title', event.target.value)} />
                    </Form.Group> */}
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Please search for address below</Form.Label>
                            <AutoComplete
                                value={this.state.service.location.coded}
                                type={"service"}
                                style={{ width: '100%' }}
                                setLocation={setLocation} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Price  *</Form.Label>
                        {this.state.isEditing ?
                            <Form.Control
                                onBlur={this.toggleEditing.bind(this)}
                                type="number"
                                className='form-input'
                                defaultValue={this.state.service ? this.state.service.price : null}
                                onChange={(event) => this.handleFieldChange('price', event.target.value)} /> :
                            <Form.Control
                                onFocus={this.toggleEditing.bind(this)}
                                // readOnly
                                type="text"
                                className='form-input'
                                defaultValue={this.state.service ? toCurrency(this.state.service.price) : null}
                                onChange={(event) => this.handleFieldChange('price', event.target.value)} />
                        }
                    </Form.Group>
                    <Form.Group
                        controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            defaultValue={this.state.service ? this.state.service.description : null}
                            onChange={(event) => this.handleFieldChange('description', event.target.value)}
                            as="textarea"
                            rows={3} />
                    </Form.Group>
                    {
                        this.state.service ? <p>{this.state.service.description}</p> : null
                    }
                    <div className='submit-button-container'>
                        <Button
                            className='submit-button'
                            type="submit"
                            onClick={(e) => this.handleUpdate(e)}
                        >
                            Update
                            </Button>
                    </div>
                </div>
            </div>
        </Container >)
    }

    render() {
        return (
            <div className='add-service-page service'>
                {this.renderRedirect()}
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>Edit Service</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {this.state.service && !this.state.loading ?
                    (
                        <Container fluid className="edit-property-body">
                            {this.state.openSubmit ? <this.Submitting /> : <this.UploadService />}
                            <br />
                        </Container>
                    ) :
                    (
                        <div>
                            {(!this.props.location || !this.props.location.state) ?
                                (
                                    <Container fluid className="edit-property-body">
                                        <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                            <Container>
                                                <h4>Failed to load service</h4>
                                                <p></p>
                                                <p></p>
                                                <p>No service ID found, please select edit service from my offered services page</p>                                                <p></p>
                                                <p></p>
                                            </Container>

                                        </div>
                                    </Container>
                                )
                                :
                                (
                                    <Container fluid className="edit-property-body">
                                        <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                            <Container>
                                                <h5 className='loading'>Loading Service...</h5>
                                                <p></p>
                                                <p></p>
                                                {/* <Spinner animation='border' variant='primary' /> */}
                                                <Far />
                                                <p></p>
                                                <p></p>
                                            </Container>

                                        </div>
                                    </Container>)}
                        </div>

                    )
                }
                {/* <Container fluid className="add-service-body">

                    {this.state.loading ?
                        <this.Submitting /> : <this.UploadService />}
                    <br />
                </Container> */}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadImages: (images, location, cb) => dispatch(uploadImages(images, location, cb))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditService)