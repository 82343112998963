/**
 * Property Object Constructor
 * @prop {String} address the street address of the property
 * @prop {String} city the city where the property is located
 * @prop {String} state the state where the property is located
 * @prop {Number} zip the property zip code
 * @prop {String} country the country where the property is located
 */
function PropertyLocation(address, city, state, zip, country, geometry) {
    this.address = address
    this.city = city
    this.state = state
    this.zip = zip
    this.country = country
    this.full = `${address}, ${city}, ${state} ${zip}, ${country}`
    this.geometry = geometry
}

/**
 * Property Description Object Constructor
 * @prop {String} type the type of property eg. 'Single Family', 'Lot'
 * @prop {String} offer the offer type
 * @prop {Number} bed the amount of bedrooms
 * @prop {Number} bath the amount of bathrooms
 * @prop {Number} size the size in square ft of the property
 * @prop {Number} year the year the property was built
 * @prop {Number} price the listing price
 * @prop {Number} timestamp the listing date
 * @prop {Number} amenities the array of amenities
 * @prop {Number} fees the hoa fees associated with the property
 */
function Description(type, offer, bed, bath, parking, size, year, price, timestamp, amenities, fees, user_description) {
    this.type = type
    this.offer = offer
    this.bed = Number(bed)
    this.bath = Number(bath)
    this.parking = Number(parking)
    this.size = Number(size)
    this.year = Number(year)
    this.price = Number(price)
    this.timestamp = timestamp
    this.amenities = amenities
    this.fees = Number(fees)
    this.user_description = user_description
}

/**
 * Property Description Object Constructor
 * @prop {Number} value the poster estimated value for the property
 * @prop {String} status the living status of the property eg. 'Vacant', 'Occupied', 'Rented'
 * @prop {String} reason the reason for listing the property
 */
function Details(value, status, reason) {
    this.value = Number(value)
    this.status = status
    this.reason = reason
}

/**
 * Property Object Constructor
 * @prop {String} title the title of the property listing
 * @prop {Object} location the location object for the property
 * @prop {Object} description the object containing the property description,
 * @prop {Details} details the object containing property details by owner
 * @prop {Array} keywords the property keywords
 * @prop {String} valuation the valuation report string or url
 * @prop {Object} owner the user object of the owner

 */
function Property(title, location, description, details, keywords, valuation, owner, id, rating, images) {
    this.id = id
    this.title = title
    this.location = location
    this.description = description
    this.details = details
    this.keywords = keywords
    this.valuation = valuation
    this.owner = owner
    this.rating = Number(rating)
    this.images = images
}

module.exports = {
    PropertyLocation,
    Description,
    Details,
    Property
}