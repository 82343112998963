import React, { useState } from 'react'
import {
    Col,
    Row,
    Card,
    Container,
    Form,
    Button,
    Collapse,
    Modal,
    Spinner,
    Badge
} from 'react-bootstrap'
import { CSVReader } from 'react-papaparse'
import './EditProperty.css'
import {
    PropertyLocation,
    Description,
    Details,
    Property
} from '../../Constructors/property'
import { Multiselect } from 'multiselect-react-dropdown';
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { User, CURRENT } from '../../Constructors/user'
import { Error, Warning, Primary, Info, Success } from '../../Alerts'
import AutoComplete from '../../AutoComplete/AutoComplete'
import { TiDeleteOutline } from 'react-icons/ti'
import { uploadImages } from '../../store/actions/userActions';
import Far from '../../Spinners/Far'
import axios from 'axios'
var request = require('request');


//1c033f60-1b17-4786-ae2d-afc14a63ce0a

class EditProperty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            redirect: false,
            target: null,
            amenities: [
                { value: 'Air Condition', id: 1 },
                { value: 'Carpet', id: 2 },
                { value: 'Fire Place', id: 3 },
                { value: 'Garage', id: 4 },
                { value: 'High Ceilings', id: 5 },
                { value: 'Laundry', id: 6 },
                { value: 'Pool', id: 7 },
                { value: 'Wood Floor', id: 8 },
                { value: 'Year Build', id: 9 },
            ],
            searchType: [
                'Buy',
                'Rent',
                'Sell',
                'Sold'
            ],
            propertyCategory: [
                'Condo',
                'Plot',
                'Single Family',
                'Multi Family',
                'Apartment',
                'Duplex',
                'Office',
                'Townhouse',
            ],
            floorPlan: false,
            propertyForm: {},
            openSubmit: false,
            loading: false,
            uploadResponse: null,
            fileName: null,
            features: [],
            currentProperty: null,
            location: null,
            featuresObj: null

        }
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        if (!this.props.location || !this.props.location.state) {
            Error({ title: 'Error Encounterd!', message: 'Failed to load property!' })
        } else {
            var id = this.props.location.state.currentProperty.id
            var that = this;
            axios.get(`/api/properties/property?id=${id}`).then(res => {
                if (res.data) {
                    try {
                        var current = res.data.msg;
                        var property_form = {
                            "title": current.title,
                            "propertyType": current.description.type,
                            "offerType": current.description.offer,
                            "user_description": current.description.user_description,
                            "size": current.description.size,
                            "address": current.location.address,
                            "city": current.location.city,
                            "state": current.location.state,
                            "zip": current.location.zip,
                            "bed": current.description.bed,
                            "bath": current.description.bath,
                            "parking": current.description.parking,
                            "keywords": current.keywords,
                            "year": current.description.year,
                            "price": current.description.price,
                            "value": current.details.value,
                            "fees": current.description.fees,
                            "living": current.details.status,
                            "reason": current.details.reason
                        }
                        console.log('Property Form', property_form);
                        that.setState({
                            currentProperty: current,
                            propertyForm: property_form,
                            features: current.description.amenities.data,
                            featuresObj: current.description.amenities.obj
                        });

                    } catch (error) {
                        console.log('Error', error)
                    }
                }
            })
        }


    }


    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.target} />
        }
    }


    handleFieldChange = (field, value) => {
        var propertyEntry = this.state.propertyForm

        if (field == 'keywords') {
            value.split(',').map(keyword => keyword.trim())
            propertyEntry[field] = value.split(',').map(keyword => keyword.trim())
            console.log(propertyEntry[field])
        } else {
            propertyEntry[field] = value
        }
        console.log('Changed', field, 'to', value)
        this.setState({ propertyForm: propertyEntry })
    };

    onSelect(selectedList, selectedItem) {
        this.setState({ features: selectedList })
        console.log(selectedList)
        var featuresObject = {}
        selectedList.map((item, key) => {
            featuresObject[`${item.value}`] = true
            if (key == selectedList.length - 1)
                this.setState({ featuresObj: featuresObject })
        })
    }

    onRemove(selectedList, removedItem) {
        this.setState({ features: selectedList })
        console.log(selectedList)
        var featuresObject = {}
        selectedList.map((item, key) => {
            featuresObject[`${item.value}`] = true
            if (key == selectedList.length - 1)
                this.setState({ featuresObj: featuresObject })
        })
    }

    imageHandler = (e) => {
        var allImages = this.state.images ? this.state.images : []
        var allImagesData = this.state.imagesData ? this.state.imagesData : []
        if (e.target.files) {
            for (var i = 0; i < e.target.files.length; i++) {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        allImages.push(reader.result)
                        this.setState({ images: allImages })
                    }
                }
                reader.readAsDataURL(e.target.files[i])
                allImagesData.push(e.target.files[i])
                this.setState({ imagesData: allImagesData })
                console.log(allImagesData)
                console.log(allImages)
            }
        }

    };

    removeImage = (idx) => {
        var allImages = this.state.images
        var allImagesData = this.state.imagesData
        allImages.splice(idx, 1)
        allImagesData.splice(idx, 1)
        this.setState({ images: allImages, imagesData: allImagesData })
    }

    removeImageCurrent = (idx) => {
        var property = this.state.currentProperty
        var images = property.images
        images.splice(idx, 1)
        property.images = images
        this.setState({ currentProperty: property })
    }

    submitImages = (e, cb) => {
        e.preventDefault();
        this.props.uploadImages(this.state.imagesData, 'property_images', (response) => {
            cb(response)
        });
    }


    handleSubmit = (e) => {
        window.scrollTo(0, 0)

        this.setState({ openSubmit: true })
        const { propertyType, offerType, user_description, bed, bath, parking, size, year, price, fees } = this.state.propertyForm
        const amenities = {
            data: this.state.features,
            obj: this.state.featuresObj
        }
        const { value, living, reason } = this.state.propertyForm
        const { address, city, state, zip, country } = this.state.propertyForm
        const { title, keywords, valuation } = this.state.propertyForm
        const timestamp = new Date().toISOString()
        const { currentProperty } = this.state
        const owner = currentProperty.owner
        var description = new Description(propertyType, offerType, bed, bath, parking, size, year, price, timestamp, amenities, fees, user_description)
        var details = new Details(value, living, reason)
        var location = this.state.location ? this.state.location :
            new PropertyLocation(address, city, state, zip, country)

        if (this.state.images) {
            var that = this
            this.submitImages(e, (images) => {
                console.log('Images from submitImages handler', images)
                if (images.length >= 1) {
                    var uploadImages = currentProperty.images ? currentProperty.images.concat(images) : images
                    var property = new Property(title, location, description, details, keywords, 'hi',
                        owner,
                        currentProperty.id, currentProperty.rating, uploadImages)
                    console.log('property', property)
                    var body = JSON.stringify({ 'property': property })
                    var request = require('request');
                    axios.post(`/api/properties/submit`, body, { headers: { 'content-type': 'application/json' } }).then(res => {
                        if (res.data) {
                            try {
                                that.setState({
                                    redirect: true,
                                    target: '/my-properties'
                                });
                                console.log(res.data);
                                that.setState({ openSubmit: false, uploadResponse: res.data.msg })
                            } catch (error) {

                            }
                        } else {
                            alert('Something went wrong');
                        }
                    })
                    // var options = {
                    //     'method': 'POST',
                    //     'url': `/api/properties/submit`,
                    //     'headers': {
                    //         'Content-Type': 'application/json'
                    //     },
                    //     body: JSON.stringify({ "property": property })

                    // };
                    // request(options, function (error, response) {
                    //     if (error) {
                    //         console.log(error)
                    //     } else {
                    //         that.setState({
                    //             redirect: true,
                    //             target: '/my-properties'
                    //         })
                    //         console.log(response.body);
                    //         that.setState({ openSubmit: false, uploadResponse: JSON.parse(response.body).msg })
                    //     }
                    // });
                } else {
                    alert('Something went wrong')
                }

            })

        } else {
            var that = this
            var property = new Property(title, location, description, details, keywords, 'hi',
                owner,
                currentProperty.id, currentProperty.rating, currentProperty.images ? currentProperty.images : [])
            var body = JSON.stringify({ 'property': property })
            axios.post(`/api/properties/submit`, body, { headers: { 'content-type': 'application/json' } }).then(res => {
                if (res.data) {
                    try {
                        that.setState({
                            redirect: true,
                            target: '/my-properties'
                        });
                        console.log(res.data);
                        that.setState({ openSubmit: false, uploadResponse: JSON.parse(res.body).msg })
                    } catch (error) {

                    }
                } else {
                    alert('Something went wrong');
                }
            });

        }

    }


    UploadProperty = () => {
        const { currentProperty } = this.state
        var setLocation = (loc) => {
            console.log("Location here", loc)
            this.setState({
                location: loc
            })
        }
        const loc = this.state.location
        return (<div>
            <div className="edit-property-card">
                <div className='edit-property-header'>
                    <Row>
                        <Col>
                            <Row>
                                <h6>EDIT PROPERTY</h6>
                            </Row>
                            <Row>
                                <div className='small-header-row-edit'></div>
                            </Row>
                        </Col>
                    </Row>


                </div>
                <div className="edit-content">
                    <Row>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <input multiple className='custom-input' type="file" accept="image/*" id="profileChange" onChange={(e) => this.imageHandler(e)} />
                        </Col>
                        <Col>
                            <Row>
                                {this.state.images == null ? null
                                    : this.state.images.map((image, idx) => {
                                        return (<Col sm={2} className="pic-holder">
                                            <div className='pic'>
                                                <div className='remove' onClick={() => this.removeImage(idx)}>
                                                    <TiDeleteOutline className='icon' />
                                                </div>
                                                <img className="img-responsive" src={image} alt="img" title="img" />
                                            </div>
                                        </Col>)
                                    })
                                }
                                {currentProperty.images == null ? null
                                    : currentProperty.images.map((image, idx) => {
                                        return (<Col sm={2} className="pic-holder">
                                            <div className='pic'>
                                                <div className='remove' onClick={() => this.removeImageCurrent(idx)}>
                                                    <TiDeleteOutline className='icon' />
                                                </div>
                                                <img className="img-responsive" src={image} alt="img" title="img" />
                                            </div>
                                        </Col>)
                                    })
                                }

                            </Row>

                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label>Property Title *</Form.Label>
                        <Form.Control className='form-input' defaultValue={(currentProperty) ?
                            currentProperty.title : 'Property Title'}
                            onChange={(event) => this.handleFieldChange('title', event.target.value)} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Property Type *</Form.Label>
                            <Form.Control as="select" defaultValue={(currentProperty) ?
                                currentProperty.description.type : "Select Property"}
                                onChange={(event) => this.handleFieldChange('propertyType', event.target.value)}>
                                <option>Select Property</option>
                                <option>Condo</option>
                                <option>Plot</option>
                                <option>Single Family</option>
                                <option>Multi Family</option>
                                <option>Apartment</option>
                                <option>Duplex</option>
                                <option>Office</option>
                                <option>Townhouse</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Offer Type *</Form.Label>
                            <Form.Control as="select" defaultValue={(currentProperty) ?
                                currentProperty.description.offer : "Select Offer"}
                                onChange={(event) => this.handleFieldChange('offerType', event.target.value)}>
                                <option>Select Offer</option>
                                <option>Buy</option>
                                <option>Rent</option>
                                <option>Sell</option>
                                <option>Sold</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6} controlId="formGridEmail">
                            <Form.Label>Description *</Form.Label>
                            <Form.Control type="text" defaultValue={(currentProperty) ?
                                currentProperty.description.user_description : "Enter property description"}
                                onChange={(event) => this.handleFieldChange('user_description', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Property Size (Ft²) *</Form.Label>
                            <Form.Control defaultValue={(currentProperty) ?
                                currentProperty.description.size : "Enter property size"}
                                onChange={(event) => this.handleFieldChange('size', event.target.value)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Please search for address below</Form.Label>
                            <AutoComplete style={{ width: '100%' }} setLocation={setLocation} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Property Address</Form.Label>
                            <Form.Control type="address" value={loc ? loc.address : (currentProperty) ?
                                currentProperty.location.address : "Property Address"}
                                onChange={(event) => this.handleFieldChange('address', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>City</Form.Label>
                            <Form.Control value={loc ? loc.city : (currentProperty) ?
                                currentProperty.location.city : "City"}
                                onChange={(event) => this.handleFieldChange('city', event.target.value)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>State</Form.Label>
                            <Form.Control type="state" value={loc ? loc.state : (currentProperty) ?
                                currentProperty.location.state : "State"}
                                onChange={(event) => this.handleFieldChange('state', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control value={loc ? loc.zip : (currentProperty) ?
                                currentProperty.location.zip : "Zip Code"}
                                onChange={(event) => this.handleFieldChange('zip', event.target.value)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Bedrooms*</Form.Label>
                            <Form.Control type="number" defaultValue={(currentProperty) ?
                                currentProperty.description.bed : "Enter number of bedrooms"}
                                onChange={(event) => this.handleFieldChange('bed', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="formGridEmail">
                            <Form.Label>Bathrooms *</Form.Label>
                            <Form.Control type="number" defaultValue={(currentProperty) ?
                                currentProperty.description.bath : "Enter number of bathrooms"}
                                onChange={(event) => this.handleFieldChange('bath', event.target.value)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Parking *</Form.Label>
                            <Form.Control type="number" defaultValue={(currentProperty) ?
                                currentProperty.description.parking : "Enter number of parking spots"}
                                onChange={(event) => this.handleFieldChange('parking', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="formGridEmail">
                            <Form.Label>Keywords *</Form.Label>
                            <Form.Control type="text" placeHolder="Enter property keywords" defaultValue={(currentProperty.keywords) ?
                                Object.keys(currentProperty.keywords).map(keyword => { return keyword }).toString() : null}
                                onChange={(event) => this.handleFieldChange('keywords', event.target.value)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Year Built*</Form.Label>
                            <Form.Control type="year" defaultValue={(currentProperty) ?
                                currentProperty.description.year : "Enter the year built"}
                                onChange={(event) => this.handleFieldChange('year', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Amenities/Features</Form.Label>
                            <Multiselect
                                selectedValues={this.state.features ?
                                    this.state.features : currentProperty ?
                                        currentProperty.description.amenities ?
                                            currentProperty.description.amenities.data : null : null}
                                defaultValue='Select Features'
                                options={this.state.amenities} // Options to display in the dropdown
                                onSelect={this.onSelect} // Function will trigger on select event
                                onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="value" // Property name to display in the dropdown options
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Asking Price *</Form.Label>
                            <Form.Control type="number" defaultValue={(currentProperty) ?
                                currentProperty.description.price : "$0.00"}
                                onChange={(event) => this.handleFieldChange('price', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="formGridEmail">
                            <Form.Label>How Much Do You Think The Home is Worth? *</Form.Label>
                            <Form.Control type="text" defaultValue={(currentProperty) ?
                                currentProperty.details.value : '$0.00'}
                                onChange={(event) => this.handleFieldChange('value', event.target.value)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>HOA Fees *</Form.Label>
                            <Form.Control type="number" defaultValue={(currentProperty) ?
                                currentProperty.description.fees : "$0.00"}
                                onChange={(event) => this.handleFieldChange('fees', event.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Is The Home Vacant, Rented Or Lived In? *</Form.Label>
                            <Form.Control as="select" defaultValue={(currentProperty) ?
                                currentProperty.details.status : "Select"}
                                onChange={(event) => this.handleFieldChange('living', event.target.value)}>
                                <option>Select</option>
                                <option>Vacant</option>
                                <option>Lived In</option>
                                <option>Rented</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Why Are You Selling? *</Form.Label>
                        <Form.Control className='form-input' defaultValue={(currentProperty) ?
                            currentProperty.details.reason : "Listing Reason"}
                            onChange={(event) => this.handleFieldChange('reason', event.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" onChange={(event) => this.setState({ floorPlan: !this.state.floorPlan })} />
                        <p>I HAVE A FLOOR PLAN</p>
                        <div className='small-header-row-edit-plan'></div>
                    </Form.Group>
                    <Collapse in={this.state.floorPlan}>
                        <div id="example-collapse-text">
                            <p>Upload Plan: </p>
                            <p></p>
                            <p></p>
                        </div>
                    </Collapse>
                    <div className='edit-button-container'>
                        <Row>
                            <Col>
                                <Button className='edit-button' type="edit"
                                    onClick={(e) => this.handleSubmit(e)}>
                                    Save
                            </Button>
                            </Col>

                            <Col>
                                <Button style={{
                                    'margin-left': 'auto',
                                    'margin-right': 'auto',
                                    'text-align': 'center',
                                    width: '250px'
                                }}
                                    variant='danger' type="edit"
                                    onClick={() => this.setState({
                                        redirect: true,
                                        target: '/my-properties'
                                    })}>
                                    Cancel
                            </Button>

                            </Col>
                        </Row>

                        {/* <this.SubmitModal /> */}
                    </div>

                </div>
            </div>
        </div>)
    }

    SubmitingProperty = () => {

        return (
            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <Container>
                    <h5 className='loading'>Submitting Property...</h5>
                    <p></p>
                    <p></p>
                    {/* <Spinner animation='border' variant='primary' /> */}
                    {/* <Invest /> */}
                    <Far />
                    <p></p>
                    <p></p>
                </Container>

            </div>
        )

    }
    render() {
        const { currentProperty } = this.state
        return (
            <div className='edit-property-page property'>
                {this.renderRedirect()}
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>Edit Property </h2>
                                <p> {currentProperty ? currentProperty.title : null}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {currentProperty ?
                    (
                        <Container fluid className="edit-property-body">
                            {this.state.openSubmit ? <this.SubmitingProperty /> : <this.UploadProperty />}
                            <br />
                        </Container>
                    ) :
                    (
                        <div>
                            {(!this.props.location || !this.props.location.state) ?
                                (
                                    <Container fluid className="edit-property-body">
                                        <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                            <Container>
                                                <h4>Failed to load property</h4>
                                                <p></p>
                                                <p></p>
                                                <p>No Property ID found, please select edit property from my properties page</p>                                                <p></p>
                                                <p></p>
                                            </Container>

                                        </div>
                                    </Container>
                                )
                                :
                                (
                                    <Container fluid className="edit-property-body">
                                        <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                            <Container>
                                                <h5 className='loading'>Loading Property...</h5>
                                                <p></p>
                                                <p></p>
                                                {/* <Spinner animation='border' variant='primary' /> */}
                                                <Far />
                                                <p></p>
                                                <p></p>
                                            </Container>

                                        </div>
                                    </Container>)}
                        </div>

                    )
                }
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadImages: (images, location, cb) => dispatch(uploadImages(images, location, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProperty)
