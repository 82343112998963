import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import {
    geocodeByAddress,
} from 'react-places-autocomplete';
import {
    PropertyLocation,
} from '../Constructors/property'
import {
    ServiceLocation,
} from '../Constructors/Service'


const AutoComplete = (props) => {
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (props.value) {
            setValue(props.value)
        }
    }, [])

    var decodeAddress = (results, coded) => {

        const map = {
            street_number: 'streetNumber',
            route: 'streetName',
            sublocality_level_1: 'city',
            locality: 'city1',
            administrative_area_level_1: 'state',
            postal_code: 'postalCode',
            country: 'country',
            county: 'county'
        }
        const add = {}

        results.address_components.forEach(component => {
            add[map[component.types[0]]] = component.long_name;
        })
        var location = props.type == 'service' ? new ServiceLocation(coded.label,
            add.city1, add.state, add.postalCode, add.country, coded) :
            new PropertyLocation(coded.label,
                add.city1, add.state, add.postalCode, add.country, results.geometry)
        console.log('locationn set to', location)
        return location;
    }



    return (<div>
        <GooglePlacesAutocomplete
            apiKey="AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY"
            selectProps={{
                value,
                onChange: (add) => {
                    var code = add;
                    setValue(add)
                    geocodeByAddress(add.label)
                        .then(results => props.setLocation(decodeAddress(results[0], code)))
                        .catch(error => console.error('Error', error))
                },
                styles: {
                    input: (provided) => ({
                        ...provided,
                        color: 'rgb(20, 27, 32)',
                        width: '200px'
                    }),
                    option: (provided) => ({
                        ...provided,
                        color: 'rgb(43, 48, 51)',
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: 'rgb(20, 27, 32)',
                    }),
                }
            }}
        />
    </div>)
}

export default AutoComplete;