import React from 'react';
import { InputGroup, Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

class Contractor extends React.Component {

    contractor = () => {
        return (
            <div className="contractor-page">
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>Contractors</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="contractor-body">
                    <Row>
                        <Col sm={4}><Card className="contractor-image" /></Col>
                        <Col className="contractor-content">
                            <Card className="contractor-text">
                                <p1>
                                As a real estate investor we know how important it is to have not one contractor but a rolodex of reliable contractors in case one doesn’t show up for a job or one decides to quit during a job, which results in time delays and profit losses. When investing in real estate local or out of your area one thing for sure you will need is a contractor. On InvestFar we’ve made it very easy and simple to build a rolodex of contractors all over the nation so you can invest far or local.
                            <br /><br />
                            You can reach out to multiple contractors specific to your needs, request price quotes, see what areas they service, so you can build a team and invest local or nationwide with confidence. Once you find a right fit save them in your favorites, reach out and build relationships so you can start building a team of contractors in the areas you wish to invest. From wall painting to roof repair, we bring you the right pros for every project on the repair list.
                            <br />
                            Consider It Done.
                            </p1>
                            <br/>
                            <Link to='./login'>
                                <Button className="register-button">Contractor Registration <BsArrowRight /></Button>
                            </Link>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    render() {
        require('./Contractors.css')
        return (
            <this.contractor />
        )
    }
}

export default Contractor