import React from 'react'
import './Far.css';
import { WhisperSpinner } from "react-spinners-kit";


// export default function Far() {

//     return (<div>
//         <div className="loader">
//             <span></span>
//             <span></span>
//             <span></span>
//             <span></span>
//         </div>

//     </div>)
// }

export default function Far() {
    return (<div style={{
        textAlign: "center",
        marginLeft: '0 auto',
        marginRight: '0 auto',
        position: 'relative',
        padding: '-20px'
    }}>
        <div style={{
            textAlign: 'center',
            marginLeft: '-52px',
            marginRight: '-55px',
            marginTop: '50px',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            padding: '50%',
        }}>
            <WhisperSpinner frontColor='#1c75bc' backColor='rgb(18,70,112)' size={100} />
        </div>
    </div>)

}