import React from 'react'
import './Consultation.css'

export default function Consultation(props) {

    return (
        <div>
            <p>Consultation Page</p>
        </div>
    )

}