import React from 'react'
import './Requests.css'

export default function Requests(props) {

    return (
        <div>
            <p>Requests Page</p>
        </div>
    )

}