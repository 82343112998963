import React, { useEffect, useState } from 'react'
import './CompareProperties.css'
import {
    Spinner,
    Card,
    Col,
    Row,
    Container,
    Form,
    Button,
    Collapse,
    Badge,
    Modal
} from 'react-bootstrap'
import { withRouter, Link, Route } from 'react-router-dom'
import { connect } from 'react-redux';
import axios from 'axios';

var request = require('request');

class compareProperties extends React.Component {

    state = {
        redirect: false,
        currentProperty: '',
        arrayOfPropertiesToCompare: '',
        data: '',
        prop2: '',
        prop3: '',
    }

    componentDidMount = () => {
        const { profile } = this.props;
        console.log('User', this.props)
        var currentProp = '';
        this.setState({
            user: profile
        })
        window.scrollTo(0, 0);
        var that = this;
        var currentFullAddress = '3609 White River Drive, Dallas, TX, USA'
        that.setState({
            loading: true
        })

        // get current property using search/query param if prop does not exist(user refreshes page)
        axios.get(`/api/properties/property?id=${this.props.location.search.substring(1)}`)
            .then(response => {
                if (response) {
                    currentProp = response.data.msg
                    console.log('current property: ', currentProp)
                    this.setState({
                        currentProperty: response
                    })

                    axios.post('/api/properties/compareProperties', {
                        state: currentProp.location.state // pass state to compare properties against  
                    })
                        .then(response => {
                            if (response) {
                                console.log('properties to compare: ', response);
                                this.setState({
                                    arrayOfPropertiesToCompare: response,
                                    loading: false
                                })
                                this.getCompareProperties()
                            }
                        }).catch(error => {
                            console.log(error);
                        })
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.setState({ loading: false })
            });

    }

    getCompareProperties = () => {
        // get the 3 properties to compare 
        var fetchedProps = this.state.arrayOfPropertiesToCompare.data.msg

        console.log('PROPERTIES TO COMPARE: ', fetchedProps)

        var fetchedProp1 = this.state.currentProperty.data.msg
        var fetchedProp2 = fetchedProps[Math.floor(Math.random() * fetchedProps.length)]
        var fetchedProp3 = fetchedProps[Math.floor(Math.random() * fetchedProps.length)]

        var prop1 = {
            "Property Name": fetchedProp1.title ? fetchedProp1.title : 'Unavailable',
            "Address": fetchedProp1.location.full ? fetchedProp1.location.full : 'Unavailable',
            "Ratings": fetchedProp1.rating ? fetchedProp1.rating.total : 'Unavailable',
            "Property Type": fetchedProp1.description.type ? fetchedProp1.description.type : 'Unavailable',
            "Offer Type": fetchedProp1.description.offer ? fetchedProp1.description.offer : 'Unavailable',
            "City": fetchedProp1.location.city ? fetchedProp1.location.city : 'Unavailable',
            "Bedrooms": fetchedProp1.description.bed ? fetchedProp1.description.bed : 'Unavailable',
            "Bathrooms": fetchedProp1.description.bath ? fetchedProp1.description.bath : 'Unavailable',
            "Property Size": fetchedProp1.description.size ? fetchedProp1.description.size : 'Unavailable',
            "Year": fetchedProp1.description.year ? fetchedProp1.description.year : 'Unavailable',
            "Asking Price": fetchedProp1.description.price ? fetchedProp1.description.price : 'Unavailable',
            "Amenities": fetchedProp1.description.amenities.data ? fetchedProp1.description.amenities.data.map((x) => x.value + ', ') : 'Unavailable',
            "Is The Home Vacant, Rented Or Lived In?": fetchedProp1.details.status ? fetchedProp1.details.status : 'Unavailable',
            "How Much Do You Think The Home Is Worth?": fetchedProp1.details.value ? fetchedProp1.details.value : 'Unavailable',
            "Why Are You Selling?": fetchedProp1.details.reason ? fetchedProp1.details.reason : 'Unavailable'
        }

        var prop2 = {
            "Property Name": fetchedProp2.title ? fetchedProp2.title : 'Unavailable',
            "Address": fetchedProp2.location.full ? fetchedProp2.location.full : 'Unavailable',
            "Ratings": fetchedProp2.rating ? fetchedProp2.rating.total : 'Unavailable',
            "Property Type": fetchedProp2.description.type ? fetchedProp2.description.type : 'Unavailable',
            "Offer Type": fetchedProp2.description.offer ? fetchedProp2.description.offer : 'Unavailable',
            "City": fetchedProp2.location.city ? fetchedProp2.location.city : 'Unavailable',
            "Bedrooms": fetchedProp2.description.bed ? fetchedProp2.description.bed : 'Unavailable',
            "Bathrooms": fetchedProp2.description.bath ? fetchedProp2.description.bath : 'Unavailable',
            "Property Size": fetchedProp2.description.size ? fetchedProp2.description.size : 'Unavailable',
            "Year": fetchedProp2.description.year ? fetchedProp2.description.year : 'Unavailable',
            "Asking Price": fetchedProp2.description.price ? fetchedProp2.description.price : 'Unavailable',
            "Amenities": fetchedProp2.description.amenities.data ? fetchedProp2.description.amenities.data.map((x) => x.value + ', ') : 'Unavailable',
            "Is The Home Vacant, Rented Or Lived In?": fetchedProp2.details.status ? fetchedProp2.details.status : 'Unavailable',
            "How Much Do You Think The Home Is Worth?": fetchedProp2.details.value ? fetchedProp2.details.value : 'Unavailable',
            "Why Are You Selling?": fetchedProp2.details.reason ? fetchedProp2.details.reason : 'Unavailable'
        }

        var prop3 = {
            "Property Name": fetchedProp3.title ? fetchedProp3.title : 'Unavailable',
            "Address": fetchedProp3.location.full ? fetchedProp3.location.full : 'Unavailable',
            "Ratings": fetchedProp3.rating ? fetchedProp3.rating.total : 'Unavailable',
            "Property Type": fetchedProp3.description.type ? fetchedProp3.description.type : 'Unavailable',
            "Offer Type": fetchedProp3.description.offer ? fetchedProp3.description.offer : 'Unavailable',
            "City": fetchedProp3.location.city ? fetchedProp3.location.city : 'Unavailable',
            "Bedrooms": fetchedProp3.description.bed ? fetchedProp3.description.bed : 'Unavailable',
            "Bathrooms": fetchedProp3.description.bath ? fetchedProp3.description.bath : 'Unavailable',
            "Property Size": fetchedProp3.description.size ? fetchedProp3.description.size : 'Unavailable',
            "Year": fetchedProp3.description.year ? fetchedProp3.description.year : 'Unavailable',
            "Asking Price": fetchedProp3.description.price ? fetchedProp3.description.price : 'Unavailable',
            "Amenities": fetchedProp3.description.amenities.data ? fetchedProp3.description.amenities.data.map((x) => x.value + ', ') : 'Unavailable',
            "Is The Home Vacant, Rented Or Lived In?": fetchedProp3.details.status ? fetchedProp3.details.status : 'Unavailable',
            "How Much Do You Think The Home Is Worth?": fetchedProp3.details.value ? fetchedProp3.details.value : 'Unavailable',
            "Why Are You Selling?": fetchedProp3.details.reason ? fetchedProp3.details.reason : 'Unavailable'
        }

        // map properties to comparison state
        var data = {
            "Property Name": {
                a: prop1['Property Name'],
                b: prop2['Property Name'],
                c: prop3['Property Name'],
            },
            "Address": {
                a: prop1['Address'],
                b: prop2['Address'],
                c: prop3['Address'],
            },
            "Ratings": {
                a: prop1['Ratings'],
                b: prop2['Ratings'],
                c: prop3['Ratings'],
            },
            "Property Type": {
                a: prop1['Property Type'],
                b: prop2['Property Type'],
                c: prop3['Property Type'],
            },
            "Offer Type": {
                a: prop1['Offer Type'],
                b: prop2['Offer Type'],
                c: prop3['Offer Type'],
            },
            "City": {
                a: prop1['City'],
                b: prop2['City'],
                c: prop3['City'],
            },
            "Bedrooms": {
                a: prop1['Bedrooms'],
                b: prop2['Bedrooms'],
                c: prop3['Bedrooms'],
            },
            "Bathrooms": {
                a: prop1['Bathrooms'],
                b: prop2['Bathrooms'],
                c: prop3['Bathrooms'],
            },
            "Property Size": {
                a: prop1['Property Size'],
                b: prop2['Property Size'],
                c: prop3['Property Size'],
            },
            "Year": {
                a: prop1['Year'],
                b: prop2['Year'],
                c: prop3['Year'],
            },
            "Asking Price": {
                a: prop1['Asking Price'],
                b: prop2['Asking Price'],
                c: prop3['Asking Price'],
            },
            "Amenities": {
                a: prop1['Amenities'],
                b: prop2['Amenities'],
                c: prop3['Amenities'],
            },
            "Is The Home Vacant, Rented Or Lived In?": {
                a: prop1['Is The Home Vacant, Rented Or Lived In?'],
                b: prop2['Is The Home Vacant, Rented Or Lived In?'],
                c: prop3['Is The Home Vacant, Rented Or Lived In?'],
            },
            "How Much Do You Think The Home Is Worth?": {
                a: prop1['How Much Do You Think The Home Is Worth?'],
                b: prop2['How Much Do You Think The Home Is Worth?'],
                c: prop3['How Much Do You Think The Home Is Worth?'],
            },
            "Why Are You Selling?": {
                a: prop1['Why Are You Selling?'],
                b: prop2['Why Are You Selling?'],
                c: prop3['Why Are You Selling?'],
            }
        }

        this.setState({
            data: data,
            prop2: fetchedProp2,
            prop3: fetchedProp3,
        })

    }

    compare = () => {
        return (
            <Card className="table-card">
                <table className='proptables'>
                    <thead>
                        <tr>
                            <th className='proptable-d-compare'><div className='compare-header-elem'>
                                <Form.Group className="compare-check">
                                    <Form.Check type="checkbox" label="Show Differences Only" />
                                </Form.Group></div></th>
                            <th className='proptable-d-compare'><div className='compare-header-elem'>
                                <Card className="madden-card">
                                    {this.state.currentProperty.data.msg.images[0] ? <img className="compare-photo" src={this.state.currentProperty.data.msg.images[0]} /> : ''}
                                </Card>
                            </div>
                            </th>
                            <th className='proptable-d-compare'><div className='compare-header-elem'>
                                <Card className="madden-card">
                                    {this.state.prop2.images[0] != "undefined" ? <img className="compare-photo" src={this.state.prop2.images[0]} /> : 'Unavailable'}
                                </Card>
                            </div>
                            </th>
                            <th className='proptable-d-compare'><div className='compare-header-elem'>
                                <Card className="madden-card">
                                    {this.state.prop3.images[0] != "undefined" ? <img className="compare-photo" src={this.state.prop3.images[0]} /> : 'Unavailable'}
                                </Card>
                            </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(this.state.data).map((k, i) => {
                            let data = this.state.data[k];
                            return (
                                <tr
                                    key={i}
                                    style={{ backgroundColor: i % 2 === 0 ? 'rgba(244,247,250,1)' : '#fff' }}
                                >
                                    <td className="row-item">{k}</td>
                                    <td className="row-item">{data.a}</td>
                                    <td className="row-item">{data.b}</td>
                                    <td className="row-item">{data.c}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div style={{ height: "100px" }} />
            </Card>
        )
    }

    render() {
        return (
            <div className='compare-listing-page'>
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>COMPARE PROPERTY</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {
                    (this.state.arrayOfPropertiesToCompare && this.state.data) ? (
                        this.state.arrayOfPropertiesToCompare.data.msg.length>2? (<this.compare />) : (
                            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Container>
                                    <h4>Not enough properties available to compare.</h4>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </Container>
                            </div>
                        )

                    ) : ((
                        <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Container>
                                <h4>Failed to load properties to compare.</h4>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                            </Container>
                        </div>))

                }

            </div >
        )
    }


}

const mapStateToProps = (state) => {
    console.log(state)
    return { profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default withRouter(connect(mapStateToProps)(compareProperties))

// export default withRouter(PropertyListing)