import React, { useEffect, useState } from "react";
import "./PropertyListing.css";
// import './ListingListingFunctions'
import {
  Spinner,
  Card,
  Col,
  Row,
  Container,
  Form,
  Button,
  Collapse,
  Badge,
  Modal,
} from "react-bootstrap";
import { withRouter, Link, Route } from "react-router-dom";
import Gallery from "./Gallery";
import NumberFormat from "react-number-format";
import { FiHeart, FiEye, FiPhoneCall, FiMapPin, FiList } from "react-icons/fi";
import { CgDetailsMore } from "react-icons/cg";
import { render } from "react-dom";
import moment from "moment";
import { Error, Primary, Success, Info, Warning } from "../../Alerts";
import { Favorite } from "../../Constructors/favorite";
import Rating from "react-rating";
import "../../styles.css";
import Far from "../../Spinners/Far";
import { connect } from "react-redux";
import axios from "axios";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import AllBy from "../AllBy/AllBy";
import Geocode from "react-geocode";
import Map from "./Map";
import Iframe from "react-iframe";

const { innerWidth: width, innerHeight: height } = window;

// import './map.css'
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

var request = require("request");
const API_KEY = "AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY";

const location = {
  address: "1600 Amphitheatre Parkway, Mountain View, california.",
  lat: 37.42216,
  lng: -122.08427,
};

class PropertyListing extends React.Component {
  state = {
    redirect: false,
    target: null,
    url: window.location.href,
    error: null,
    name: "",
    listing: "",
    newListing: "",
    post: null,
    amenities: [
      {
        name: "AIR CONDITION",
        icon: "https://www.investfar.com/assets/feature-icons/ic_air_conditioner_selected.png",
      },
      {
        name: "CARPET",
        icon: "https://www.investfar.com/assets/feature-icons/ic_carpet_selected.png",
      },
      {
        name: "FIRE PLACE",
        icon: "https://www.investfar.com/assets/feature-icons/ic_fire_place_selected.png",
      },
      {
        name: "GARAGE",
        icon: "https://www.investfar.com/assets/feature-icons/ic_garage_selected.png",
      },
      {
        name: "HIGH CEILINGS",
        icon: "https://www.investfar.com/assets/feature-icons/ic_high_ceilings.png",
      },
      {
        name: "LAUNDARY",
        icon: "https://www.investfar.com/assets/feature-icons/ic_laudry_selected.png",
      },
      {
        name: "POOL",
        icon: "https://www.investfar.com/assets/feature-icons/ic_pool_selected.png",
      },
      {
        name: "WOOD FLOOR",
        icon: "https://www.investfar.com/assets/feature-icons/ic_wood_selected.png",
      },

      {
        name: "YEAR BUILT",
        icon: "https://www.investfar.com/assets/feature-icons/ic_yearbuilt_selected.png",
      },
    ],
    pass: null,
    user: null,
    name: "",
    offerName: "", // make an offer
    offerEmail: "", // make an offer
    offerPhone: "", // make an offer
    offerMessage: "", // make an offer
    offer: "", // make an offer
    propertyOwner: "info@investfar.com", // update on load
    school_collapse: false,
    walk_collapse: false,
    loading: false,
    nearbyProperties: null,
    nearbySchools: null,
    compare_collapse: false,
    compareProperties: true,
    propertyLoading: false,
    valuation: false,
    inspection: false,
    offerRequest: false,
    showMap: false,
    mapLoaded: false,
  };

  toggleCompare = () => {
    if (this.state.compare_collapse == true) {
      this.setState({
        compare_collapse: false,
      });
    } else {
      // fetch
      this.setState({
        compare_collapse: true,
        walk_collapse: false,
        school_collapse: false,
      });
    }
  };

  toggleWalk = () => {
    if (this.state.walk_collapse == true) {
      this.setState({
        walk_collapse: false,
        nearbyProperties: "",
      });
    } else {
      this.fetchWalk();
      this.setState({
        walk_collapse: true,
        school_collapse: false,
        compare_collapse: false,
      });
    }
  };

  toggleSchool = () => {
    if (this.state.school_collapse == true) {
      this.setState({
        school_collapse: false,
        nearbySchools: "",
      });
    } else {
      this.fetchSchools();
      this.setState({
        school_collapse: true,
        walk_collapse: false,
        compare_collapse: false,
      });
    }
  };

  fetchWalk = (property) => {
    var location = this.state.post.location.geometry.location;
    var that = this;
    that.setState({
      loading: true,
    });
    axios
      .post("/api/services/nearby", {
        lat: this.state.post.location.geometry.location.lat,
        lng: this.state.post.location.geometry.location.lng,
      })
      .then((response) => {
        if (response) {
          console.log(response);
          this.setState({
            nearbyProperties: response,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchSchools = (property) => {
    var location = this.state.post.location.geometry.location;
    var that = this;
    that.setState({
      loading: true,
    });
    axios
      .post("/api/services/nearbySchools", {
        lat: this.state.post.location.geometry.location.lat,
        lng: this.state.post.location.geometry.location.lng,
      })
      .then((response) => {
        if (response) {
          console.log(response);
          this.setState({
            nearbySchools: response,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  sendOfferEmail = () => {
    this.setState({
      offerRequest: true,
    });
    var that = this;
    const { profile } = this.props;
    var propertyName = this.state.post.title;
    // var propertyOwner = this.state.propertyOwner;
    var propertyOwner = profile.email;
    var email = this.state.offerEmail;
    axios
      .post("/api/services/send-offer-request", {
        property: `${propertyName}`,
        contactOffer: `${this.state.offer}`,
        contactName: `${this.state.offerName}`,
        contactEmail: `${this.state.offerEmail}`,
        contactPhone: `${this.state.offerPhone}`,
        message: `${this.state.offerMessage}`,
        propertyOwner: `${propertyOwner}`,
      })
      .then((response) => {
        that.setState({
          offerRequest: false,
        });
        if (response) {
          console.log(response);
          Info({
            title: "Message Delivered",
            message: "A representative will be in contact with you shortly! ",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Error({
          title: "Error Encountered",
          message: "Error encountered when sending contact email!",
        });
      });
  };

  sendEmail = (email) => {
    this.setState({
      [email.type]: true,
    });
    console.log("This is the user", this.state.user);

    if (this.state.user && this.state.user.user_name) {
      var post = this.state.post;

      var data = JSON.stringify({
        subject: {
          headline: email.headline,
          subHeadline: email.subHeadline,
        },
        recepient: "investfardev@gmail.com",
        message: [
          {
            name: "Property",
            tag: "h1",
            color: "#2377ba",
            info: this.state.post.title + " - " + this.state.post.id,
          },
          {
            name: "Address",
            tag: "h2",
            color: "#262626",
            info:
              post.location && post.location.full
                ? post.location.full.replace(", undefined", "")
                : post.location && post.location.address
                ? post.location.address.replace(", undefined", "")
                : "",
          },
          {
            name: "Requestor",
            tag: "h2",
            color: "#262626",
            info: this.state.user
              ? this.state.user.user_name + " | " + this.state.user.user_id
              : "Not signed in",
          },
        ],
      });
      var that = this;
      var config = {
        method: "post",
        url: "/api/additional/send-email",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          that.setState({
            [email.type]: false,
          });
          Info({
            title: email.type + " Report Ordered",
            message: "A representative will be in touch with you soon!",
          });
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.setState({
        [email.type]: false,
      });
      Error({
        title: "Cannot Order " + email.type + " Report",
        message: "please log in first",
      });
    }
  };

  renderRedirect = () => {
    if (this.state.redirect && false) {
      return (
        <Redirect
          exact
          to={{
            pathname: this.state.target,
            state: this.state.pass,
          }}
        />
      );
    } else if (this.state.redirect) {
      return (
        <Router>
          <Redirect
            exact
            from="/property"
            to={{
              pathname: `/all-by/${this.state.post.owner.id}`,
              state: this.state.pass,
            }}
          />
          <Route
            path={`/all-by/${this.state.post.owner.id}`}
            component={AllBy}
          />
        </Router>
      );
    }
  };

  getProperty = (id, cb) => {
    var that = this;
    if (id && id !== "undefined") {
      axios
        .get(`/api/properties/property?id=${id}`)
        .then((res) => {
          if (res.data) {
            var property = [];
            try {
              property = JSON.parse(res.body).msg;
              cb(property);
            } catch (error) {
              console.log("Error", error);
              that.setState({ error: error.toString() });
              Error({ title: "Error Encountered", message: error.toString() });
              cb([]);
            }
          } else {
            that.setState({ error: "Failed to Get Property" });
            Error({
              title: "Error Encountered",
              message: "Failed to Get Property",
            });
            cb(null);
          }
        })
        .catch((error) => {
          that.setState({ error: error.toString() });
          Error({ title: "Error Encountered", message: error.toString() });
          cb(null);
        });
    } else {
      Error({ title: "Error", message: "Property id not found" });
    }

    // var options = {
    //     'method': 'GET',
    //     'url': `/api/properties/property?id=${id}`,
    //     'headers': {
    //     }
    // };
    // request(options, function (error, response) {
    //     if (error) {
    //         console.log('Error', error)
    //         that.setState({ error: error.toString() })
    //         Error({ title: 'Error Encountered', message: error.toString() })
    //         cb(null)
    //     } else {
    //         var property = []
    //         try {
    //             property = JSON.parse(response.body).msg
    //             cb(property)
    //         } catch (error) {
    //             console.log('Error', error)
    //             that.setState({ error: error.toString() })
    //             Error({ title: 'Error Encountered', message: error.toString() })
    //             cb([])
    //         }
    //     }
    // });
  };
  componentDidMount = () => {
    const { profile } = this.props;
    console.log("User", profile);
    this.setState({
      user: profile,
    });
    window.scrollTo(0, 0);

    var userId = this.props.location.search;
    var cleanedUserId = userId.substring(1);

    axios
      .get(
        `/api/properties/property?id=${cleanedUserId}`,
        {},
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        if (response) {
          console.log(response);
          this.setState({
            post: response.data.msg,
            pass: {
              id: response.data.msg.owner.id,
              name: response.data.msg.owner.name,
            },
          });
          var body = JSON.stringify({
            id: response.data.msg.id,
            total: response.data.msg.seenBy ? response.data.msg.seenBy + 1 : 1,
          });

          axios
            .post("api/properties/see", body)
            .then(function (response) {
              console.log("Seen hoe", JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log("error hoe", error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addFavorite = () => {
    if (this.state.user.user_id == this.state.post.owner.id) {
      Error({ title: "Failed", message: "Cannot favor your own listing" });
    } else {
      // var body = JSON.stringify({
      //     "property": new Favorite(this.state.post, new Date().toISOString()),
      //     "user_id": this.state.user.user_id
      // });
      var body = JSON.stringify({
        property: this.state.post.id,
        user_id: this.state.user.user_id,
      });
      console.log(body);
      axios
        .post(`/api/properties/favor`, body, {
          headers: { "content-type": "application/json" },
        })
        .then((res) => {
          if (res.data) {
            if (res.data.success) {
              Success({
                title: "Success",
                message: "Property added to favorites",
              });
            } else {
              Error({
                title: "Failed",
                message: "Failed to add property to favorites",
              });
            }
          } else {
            Error({
              title: "Failed",
              message: "Failed to add property to favorites",
            });
          }
          console.log(res.data);
        })
        .catch((error) => {
          Error({ title: "Failed", message: error.toString() });
        });
      // var options = {
      //     'method': 'POST',
      //     'url': `/api/properties/favor`,
      //     'headers': {
      //         'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //         "property": new Favorite(this.state.post, new Date().toISOString()),
      //         "user_id": this.state.user.user_id
      //     })
      // };
      // request(options, function (error, response) {
      //     if (error) {
      //         Error({ title: 'Failed', message: 'Failed to add property to favorites' })
      //     } else {
      //         if (JSON.parse(response.body).success) {
      //             Success({ title: 'Success', message: 'Property added to favorites' })
      //         } else {
      //             Error({ title: 'Failed', message: 'Failed to add property to favorites' })
      //         }
      //     }
      //     console.log(response.body);
      // });
    }
  };

  handleRating = (value) => {
    if (this.state.user.user_id == this.state.post.owner.id) {
      Error({ title: "Failed", message: "Cannot rate your own listing" });
    } else {
      var average = this.state.post.rating
        ? Number(this.state.post.rating.average)
        : value;
      var total = this.state.post.rating
        ? Number(this.state.post.rating.total) + 1
        : 1;

      var newAverage = this.state.post.rating
        ? (average * (total - 1)) / total + value / total
        : value;

      var ratingBody = {
        total: total,
        average: newAverage,
      };
      console.log(
        "Old Rating",
        this.state.post.rating,
        "\nNewAverage",
        ratingBody
      );
      var body = JSON.stringify({
        property_id: this.state.post.id,
        rating: ratingBody,
      });
      axios
        .post(`/api/properties/rate`, body)
        .then((res) => {
          if (res.data) {
            try {
              if (res.data.success) {
                Success({
                  title: "Success",
                  message: "Property rating submitted",
                });
              } else {
                Error({ title: "Error", message: "Failed to rate property" });
              }
            } catch {
              Error({ title: "Error", message: "Failed to rate property" });
            }
          } else {
            Error({ title: "Error", message: "Failed to rate property" });
          }
        })
        .catch((error) => {
          Error({ title: "Error", message: "Failed to rate property" });
        });
    }
  };

  obtainFunding = () => {
    console.log("clicked obtain funding");
    var url = "www.google.com";
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  renderWalkComponents = (component) => {
    return (
      <div>
        <Card className="listing-component-card">
          {this.state.nearbyProperties ? (
            this.state.nearbyProperties.data.map((item) => {
              return (
                <Container className="component-item-container">
                  <Row>
                    <Col xs={2}>
                      <img className="icon-photo" src={item.icon} />
                    </Col>
                    <Col>
                      <Row>{item.placeName}</Row>
                      <Row>{item.address}</Row>
                    </Col>
                    <Col>
                      {item.placeTypes.map((type) => {
                        return (
                          <Row className="component-badge">
                            {" "}
                            <Badge variant="dark">
                              {type.charAt(0)
                                ? type.charAt(0).toUpperCase() +
                                  type.replace(/_/g, " ").slice(1)
                                : ""}
                            </Badge>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                </Container>
              );
            })
          ) : (
            <Spinner className="component-spinner" animation="border" />
          )}
        </Card>
      </div>
    );
  };

  renderSchoolComponents = (component) => {
    return (
      <div>
        <Card className="listing-component-card">
          {this.state.nearbySchools ? (
            this.state.nearbySchools.data.map((item) => {
              return (
                <Container className="component-item-container">
                  <Row>
                    <Col xs={2}>
                      <img className="icon-photo" src={item.icon} />
                    </Col>
                    <Col>
                      <Row>{item.placeName}</Row>
                      <Row>{item.address}</Row>
                    </Col>
                    <Col>
                      {item.placeTypes.map((type) => {
                        return (
                          <Row className="component-badge">
                            {" "}
                            <Badge variant="dark">
                              {type.charAt(0)
                                ? type.charAt(0).toUpperCase() +
                                  type.replace(/_/g, " ").slice(1)
                                : ""}
                            </Badge>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                </Container>
              );
            })
          ) : (
            <Spinner className="component-spinner" animation="border" />
          )}
        </Card>
      </div>
    );
  };

  renderCompareComponents = (component) => {
    return (
      <div>
        <Card className="listing-component-compare-card">
          <Container className="component-compare-container">
            <Row>
              <Col>
                <Row>
                  <Card className="compare-image">Property</Card>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Card className="compare-image">Property</Card>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Card className="compare-image">Property</Card>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  };

  DisplayListing = () => {
    const mapRef = React.useRef(null);
    const { post } = this.state;
    console.log("Location", this.state.post.location);
    const lat =
      this.state.post &&
      this.state.post.location &&
      this.state.post.location.geometry
        ? this.state.post.location.geometry.location.lat
        : null;
    const lng =
      this.state.post &&
      this.state.post.location &&
      this.state.post.location.geometry
        ? this.state.post.location.geometry.location.lng
        : null;

    const amenities =
      post && post.description
        ? post.description.amenities.data
          ? post.description.amenities.data
          : post.description.amenities
        : [];
    return (
      <Row>
        {post && post.description ? (
          <Col sm={9} style={{ marginRight: "-15px", paddingRight: "15px" }}>
            <Container fluid>
              {post && post.location ? (
                <Gallery
                  images={post.images}
                  title={post.title + "  -  " + post.location.full}
                />
              ) : null}
            </Container>
            <Container fluid>
              <Row>
                <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Description
                </h3>
              </Row>
              <Row>
                <div className="small-header-row"></div>
              </Row>
              <Row style={{ marginRight: "10px" }}>
                <Card className="property-description-card">
                  <Card.Header>
                    <Row>
                      <Col className="align-left">
                        {post && post.location ? (
                          <div style={{ fontSize: "14px" }}>
                            <FiMapPin />{" "}
                            {post.location && post.location.full
                              ? post.location.full.replace(", undefined", "")
                              : post.location && post.location.address
                              ? post.location.address.replace(", undefined", "")
                              : ""}
                          </div>
                        ) : null}
                      </Col>
                      <Col sm={5} className="align-right">
                        <Rating
                          initialRating={
                            this.state.post
                              ? this.state.post.rating
                                ? this.state.post.rating.average
                                : null
                              : null
                          }
                          emptySymbol="fa fa-star-o fa-2x"
                          fullSymbol="fa fa-star fa-2x"
                          fractions={2}
                          onChange={(value) => this.handleRating(value)}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    {post && post.description ? (
                      <Row className="body">
                        <Col>
                          <label className="description-title">
                            PROPERTY TYPE
                          </label>
                          <p> {post.description.type}</p>
                        </Col>
                        <Col>
                          <label className="description-title">
                            OFFER TYPE
                          </label>
                          <p> {post.description.offer}</p>
                        </Col>
                        <Col>
                          <label className="description-title">CITY</label>
                          <p>
                            {" "}
                            {post && post.location ? post.location.city : null}
                          </p>
                        </Col>
                      </Row>
                    ) : null}
                    <div className="large-header-row" />
                    {post && post.description ? (
                      <Row className="body">
                        <Col>
                          <label className="description-title">BEDROOMS</label>
                          <p> {post.description.bed}</p>
                        </Col>
                        <Col>
                          <label className="description-title">BATHROOMS</label>
                          <p> {post.description.bath}</p>
                        </Col>
                        <Col>
                          <label className="description-title">
                            PROPERTY SIZE
                          </label>
                          <p> {post.description.size} FT</p>
                        </Col>
                      </Row>
                    ) : null}
                    <div className="large-header-row" />
                    {post && post.description ? (
                      <Row className="body">
                        <Col>
                          <label className="description-title">YEAR</label>
                          <p> {post.description.year}</p>
                        </Col>
                        <Col>
                          <label className="description-title">
                            ASKING PRICE
                          </label>
                          <p> ${post.description.price}</p>
                        </Col>
                        <Col>
                          <label className="description-title">DATE</label>
                          <p>
                            {" "}
                            {moment(
                              new Date(post.description.timestamp)
                            ).format("MMMM Do YYYY, h:mm:ss a")}
                          </p>
                        </Col>
                      </Row>
                    ) : null}
                    <div className="large-header-row" />
                    <Row className="body">
                      <Col>
                        <Row style={{ marginLeft: "5px" }}>
                          <label className="description-title">AMENITIES</label>
                        </Row>
                        <p></p>
                        <p></p>
                        <Container>
                          <Row>
                            {amenities.map((amenity) => {
                              return (
                                <Col className="amenity-column" sm={2}>
                                  <div className="icon-block">
                                    <img
                                      className="amenity-icon"
                                      alt=""
                                      src={
                                        this.state.amenities && amenity.id
                                          ? this.state.amenities[amenity.id - 1]
                                              .icon
                                          : null
                                      }
                                    />{" "}
                                  </div>
                                  <p className="description-title">
                                    {amenity && amenity.value
                                      ? amenity.value
                                      : "Unknown"}
                                  </p>
                                </Col>
                              );
                            })}
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <div className="large-header-row" />
                    <Row className="decription-keywords">
                      <p>
                        How Much Do You Think The Home Is Worth :{" "}
                        <strong>${post.details.value}</strong>
                      </p>
                    </Row>
                    <div className="large-header-row" />
                    <Row className="decription-keywords">
                      <p>
                        Is The Home Vacant, Rented Or Lived In :{" "}
                        <strong>{post.details.status}</strong>
                      </p>
                    </Row>
                    <div className="large-header-row" />
                    <Row className="decription-keywords">
                      <p>
                        Why Are You Selling :{" "}
                        <strong>{post.details.reason}</strong>
                      </p>
                    </Row>
                    <div className="large-header-row" />
                    <Row className="body">
                      <Container>
                        <Row>
                          <Col>
                            <Button
                              disabled={this.state.valuation}
                              onClick={() => {
                                this.sendEmail({
                                  type: "valuation",
                                  headline: "Investfar",
                                  subHeadline:
                                    "Property Valuation Report Request",
                                });
                              }}
                              className="allby-button"
                            >
                              {this.state.valuation
                                ? "Loading..."
                                : "Property Valution Report"}
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              disabled={this.state.inspection}
                              onClick={() => {
                                this.sendEmail({
                                  type: "inspection",
                                  headline: "Investfar",
                                  subHeadline:
                                    "Property Inspection Report Request",
                                });
                              }}
                              className="allby-button"
                            >
                              {this.state.inspection
                                ? "Loading..."
                                : " Order A Property Inspection"}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Row>
                    <div className="large-header-row" />

                    <Row className="body">
                      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                        <Row>
                          <Row
                            style={{ marginLeft: "auto", marginRight: "auto" }}
                          >
                            Location Pre-Approved
                          </Row>
                          <Button
                            className="allby-button"
                            onClick={() => {
                              this.openInNewTab(
                                "https://www.investfarcapital.com/go/loan-application/"
                              );
                            }}
                          >
                            Obtain Funding
                          </Button>
                        </Row>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            </Container>
            <Row>
              <Card className="property-tools-card">
                <Row>
                  <Col>
                    <Button
                      className="tools-button"
                      onClick={() => {
                        this.openInNewTab(
                          "https://www.calculator.net/rental-property-calculator.html"
                        );
                      }}
                    >
                      Cash Flow Calculator
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      name="walk_collapse"
                      onClick={() => this.toggleWalk()}
                      className="tools-button"
                    >
                      Walkscore
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      name="school_collapse"
                      onClick={() => this.toggleSchool()}
                      className="tools-button"
                    >
                      School
                    </Button>
                  </Col>
                  <Col>
                    {/* <Link className="compare-link" to={`compare/${this.state.post.id}`}> */}
                    <Link
                      className="compare-link"
                      to={{
                        pathname: "/compare", // should add property name to path to be able to refresh and still stay on the page
                        search: `${this.state.post.id}`,
                        currentProperty: this.state.post,
                      }}
                    >
                      <Button name="showCompareModal" className="tools-button">
                        Compare
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Collapse
                    className="component-collapse-body"
                    in={this.state.school_collapse}
                  >
                    <div id="example-collapse-text">
                      {this.renderSchoolComponents("School")}
                    </div>
                  </Collapse>
                  <Collapse
                    className="component-collapse-body"
                    in={this.state.walk_collapse}
                  >
                    <div id="example-collapse-text">
                      {this.renderWalkComponents("Walk")}
                    </div>
                  </Collapse>
                  <Collapse
                    className="component-collapse-body"
                    in={this.state.compare_collapse}
                  >
                    <div id="example-collapse-text">
                      {this.renderCompareComponents("Compare")}
                    </div>
                  </Collapse>
                </Row>
              </Card>
            </Row>
            <Row>
              <Container fluid>
                <Card className="property-details-card">
                  <Card.Header>
                    <CgDetailsMore /> Details
                  </Card.Header>
                  <Card.Body className="body">
                    <Row ref={mapRef} className="decription-keywords">
                      {post.description.user_description ? (
                        <p>{post.description.user_description}</p>
                      ) : (
                        <p></p>
                      )}
                    </Row>
                  </Card.Body>
                  {/* <Card.Footer > */}
                  {/* <Map location={{
                                        lat: this.state.post && this.state.post.location && this.state.post.location.geometry ? this.state.post.location.geometry.location.lat : null,
                                        lng: this.state.post && this.state.post.location && this.state.post.location.geometry ? this.state.post.location.geometry.location.lng : null
                                    }} zoomLevel={1} /> */}
                  {/* </Card.Footer> */}
                </Card>
              </Container>
            </Row>
            <Row>
              <div style={{ height: "20px" }} />
            </Row>
            <Row>
              <Container fluid>
                <Card className="property-details-card">
                  <Card.Header>
                    <FiMapPin /> Map
                  </Card.Header>
                  <Card.Footer style={{ padding: 0 }}>
                    {!this.state.mapLoaded ? (
                      <div style={{ textAlign: "center" }}>
                        <div style={{ height: "30px" }} />
                        <h5 className="loading">Loading</h5>
                        <div style={{ height: "20px" }} />
                        {/* <Spinner variant='primary' animation='border' /> */}
                        <Far />
                      </div>
                    ) : null}
                    <div style={{ width: "100%" }}>
                      <iframe
                        width="100%"
                        height="600"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        onLoad={() => this.setState({ mapLoaded: true })}
                        src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY&center=${lat},${lng}&zoom=18`}
                      ></iframe>
                    </div>
                    {/* <Iframe url={`https://www.google.com/maps/embed/v1/view?key=AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY&center=${lat},${lng}&zoom=18`}
                                        loading="LOading"
                                        width="100%"
                                        height={"600px"}
                                        frameborder="0"
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        onLoad={() => this.setState({ mapLoaded: true })} /> */}
                  </Card.Footer>
                  {/* <Card>
                                    <div style={{ width: "100%" }}>
                                        <iframe width="100%"
                                            height="600"
                                            frameborder="0"
                                            scrolling="no"
                                            marginheight="0"
                                            marginwidth="0"
                                            src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY&center=${lat},${lng}&zoom=18`}
                                        >
                                        </iframe>
                                        <a href="https://www.mapsdirections.info/en/measure-map-radius/">Map radius measure</a>
                                    </div>
                                </Card> */}
                </Card>
              </Container>
            </Row>
          </Col>
        ) : null}
        {post && post.description ? (
          <Col sm={3}>
            <Container fluid>
              <Row
                style={{ cursor: "pointer" }}
                onClick={() => this.addFavorite()}
              >
                <div className="sidebar-light">
                  {" "}
                  <FiHeart /> Add to favorites
                </div>
              </Row>
              <Row>
                <div className="sidebar-light">
                  {" "}
                  <FiEye /> seen by {post.seenBy ? post.seenBy : 1}
                </div>
              </Row>
              <Row>
                <div className="sidebar-dark">
                  <NumberFormat
                    value={
                      post && post.description ? post.description.price : 0.0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </div>
              </Row>
              <Row>
                <div className="sidebar-light">
                  {" "}
                  <FiPhoneCall />{" "}
                  {post.owner.phone ? post.owner.phone : "+ 1-855-737-4338"}
                </div>
              </Row>
              <Row
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // window.scrollTo(0, height * )
                  // this.setState({ showMap: true })
                  mapRef.current.scrollIntoView();
                }}
              >
                <div className="sidebar-light">
                  <FiMapPin /> See Map
                </div>
              </Row>
              <Row>
                <a
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  href="https://bitpay.com/signup?personal"
                >
                  {/* <strong>BUY IT NOW</strong>
                                <p> WITH SHIELDPAY</p> */}
                  <img
                    src="https://bitpay.com/cdn/merchant-resources/pay-with-bitpay-card-group.svg"
                    alt="BitPay Supported Currencies"
                    width="250px"
                  />
                </a>
              </Row>
              <Row>
                <h6 style={{ marginTop: "10px", marginBottom: "10px" }}>
                  MAKE AN OFFER
                </h6>
              </Row>
              <Row>
                <div className="small-header-row"></div>
              </Row>
              <Row>
                <Card className="make-offer-card">
                  <Form className="offer-form">
                    <Form.Group>
                      <Form.Control
                        name="offerName"
                        onChange={this.changeHandler}
                        placeholder="Your Name"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        name="offerEmail"
                        onChange={this.changeHandler}
                        placeholder="Email"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        name="offerPhone"
                        onChange={this.changeHandler}
                        placeholder="Phone"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        name="offer"
                        onChange={this.changeHandler}
                        placeholder="$0.00"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        className="offer-message"
                        name="offerMessage"
                        onChange={this.changeHandler}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                    <Button
                      disabled={this.state.offerRequest}
                      onClick={() => this.sendOfferEmail()}
                      className="send-button"
                    >
                      <strong>
                        {this.state.offerRequest ? "Loading..." : "SEND"}
                      </strong>
                    </Button>
                    <Row></Row>
                    <Container fluid>
                      <Row>
                        <h6 style={{ marginTop: "15px", marginBottom: "10px" }}>
                          PROFILE
                        </h6>
                      </Row>
                      <Row>
                        <div className="small-header-row"></div>
                      </Row>
                      <Row>
                        <div className="sidebar-light">+ 1-855-737-4338</div>
                      </Row>

                      <Row>
                        <div className="sidebar-light"> info@investfar.com</div>
                      </Row>
                      <Row>
                        <Button
                          onClick={() => {
                            this.setState({
                              redirect: true,
                              target: `/all-by/${post.owner.id}`,
                              pass: {
                                id: post.owner.id,
                                name:
                                  post.owner && post.owner.name
                                    ? post.owner.name.toUpperCase()
                                    : "NO NAME",
                              },
                            });
                          }}
                          className="allby-button"
                        >
                          {/* <Link style={{ width: '100%' }} to={{
                                                pathname: '/all-by',
                                                state: { id: post.owner.id, name: post.owner.name.toUpperCase() }
                                            }}> */}
                          ALL BY{" "}
                          {post.owner && post.owner.name
                            ? post.owner.name.toUpperCase()
                            : "NO NAME"}
                          {/* </Link> */}
                        </Button>
                      </Row>
                    </Container>
                  </Form>
                </Card>
              </Row>
            </Container>
          </Col>
        ) : null}
      </Row>
    );
  };

  FetchProperty = () => {
    var id = null;
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.currentProperty
    ) {
      this.setState({ post: this.props.location.state.currentProperty });
      return null;
    } else {
      console.log("props", this.props);
      try {
        this.getProperty(this.props.location.state.id, (property) => {
          if (property == null) {
            return (
              <div
                style={{
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Container>
                  <h4>Failed to load property</h4>
                  <p></p>
                  <p></p>
                  <p>No Property Found</p> <p></p>
                  <p></p>
                </Container>
              </div>
            );
          } else {
            this.setState({ post: property });
            return <div></div>;
          }
        });
      } catch (error) {
        return (
          <div
            style={{
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Container>
              <Spinner animation="border" />
              {/* <h4>Failed to load property</h4>
                            <p></p>
                            <p></p>
                            <p>{String(error)}</p>                                                <p></p>
                            <p></p> */}
            </Container>
          </div>
        );
      }
      return <div></div>;
    }
  };

  render() {
    return (
      <div className="property-listing-page">
        {this.renderRedirect()}
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>{this.state.post ? this.state.post.title : "NO NAME"}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="listing-content">
          {this.state.post ? (
            <this.DisplayListing />
          ) : (
            <div>
              {!this.props.location.currentProperty ? (
                <Container fluid className="edit-property-body">
                  <this.FetchProperty />
                </Container>
              ) : (
                <Container fluid className="edit-property-body">
                  <div
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Container>
                      <h5 className="loading">Loading Property</h5>
                      <p></p>
                      <p></p>
                      {/* <Spinner animation='border' variant='primary' /> */}
                      <Far />
                      <p></p>
                      <p></p>
                    </Container>
                  </div>
                </Container>
              )}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    profile: state.firebase.profile,
    user: state.user.user,
    authSuccess: state.auth.authSuccess,
    jwtToken: state.auth.jwtToken,
  };
};

export default withRouter(connect(mapStateToProps)(PropertyListing));

// export default withRouter(PropertyListing)
