import React from 'react';
import { InputGroup, Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { HiDocument } from 'react-icons/hi';
import Iframe from 'react-iframe'
import Far from '../Spinners/Far'

const { innerWidth: width, innerHeight: height } = window;




class OpenHomes extends React.Component {
    constructor(props) {
        super(props);
        this._ref = React.createRef();
    }
    state = {
        test: '',
        loading: true,
    }

    componentDidMount() {
        var cid = 155924
        const script = document.createElement("script");
        var that = this
        script.src = `https://www.idxhome.com/eureka/ihf-eureka.js?1622542044735`
        script.type = "text/javascript";
        script.async = false;
        script.onload = () => {
            that.setState({
                loaded: true
            })
        };
        document.getElementsByTagName("head")[0].appendChild(script);
    }

    open = () => {
        return (
            <div className="mls-page">
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>OPEN HOMES</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                { this.state.loading ?
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ height: '30px' }} />
                        <h5 className='loading'>Loading</h5>
                        <div style={{ height: '100px' }} />
                        {/* <Spinner variant='primary' animation='border' /> */}
                        <Far />
                        <div style={{ height: height }} />

                    </div>
                    : null
                }
                <Iframe url="https://www.idxhome.com/openhomes/155924"
                    width="100%"
                    height={height * 2}
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                    onLoad={() => this.setState({ loading: false })} />
                <div style={{ height: '30px' }} />
            </div >
        )
    }

    render() {
        require('./mlsPropertySearch.css')
        return (
            <this.open />
        )
    }
}

export default OpenHomes