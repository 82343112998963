import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Card,
  Container,
  Collapse,
} from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  FaPlus,
  FaHome,
  FaUsers,
  FaDollarSign,
  FaPuzzlePiece,
} from "react-icons/fa";
import { HiDocument } from "react-icons/hi";
import { BsArrowRight } from "react-icons/bs";
import { ImPhone } from "react-icons/im";

class HomeImprovement extends React.Component {
  state = {
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
  };

  toggleCollapse = (event) => {
    this.setState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  improve = () => {
    return (
      <div className="improvement-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>HOME IMPROVEMENT</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="improvement-body">
          <h6 style={{ textAlign: "center" }}>
            INVESTFAR HOME IMPROVEMENT NETWORK
          </h6>
          <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
            Match projects with a professional contractor
          </h2>
          <br />
          <div className="improvement-description">
            <span>
              We've teamed up with Contractor Connection to offer our Investors
              Club members free access to a pre-screened network of professional
              contractors. Whether your project is a significant home repair or
              your dream remodel, let us help you take the guesswork out of
              finding a professional contractor.
            </span>
            <br />
            <br />
            {/* <Link to='./login'> */}
            <Button
              className="improvement-button"
              onClick={() => {
                this.openInNewTab(
                  "https://www.contractorconnection.com/Home/Consumer/Partner/Why-Us?Referral=Investfar"
                );
              }}
            >
              {" "}
              Get Started <BsArrowRight size={20} />
            </Button>
            {/* </Link> */}
          </div>
          <br />
          <div className="card-container">
            <Row>
              <Col>
                <Card className="card">
                  <div className="icon-container">
                    <FaUsers className="icon" color="white" />
                  </div>
                  <div className="card-content">
                    <h6>Pre-screened contractors</h6>
                    <p1 style={{ fontSize: 14 }}>
                      Get referred to a network of contractors who have been
                      thoroughly vetted.
                    </p1>
                  </div>
                  <br />
                </Card>
              </Col>
              <Col>
                <Card className="card">
                  <div className="icon-container">
                    <FaDollarSign className="icon" color="white" />
                  </div>
                  <div className="card-content">
                    <h6>Fair and reasonable estimate</h6>
                    <p1 style={{ fontSize: 14 }}>
                      Contractor Connection's Quality Assurance team reviews all
                      proposals.
                    </p1>
                  </div>
                  <br />
                </Card>
              </Col>
              <Col>
                <Card className="card">
                  <div className="icon-container">
                    <FaPuzzlePiece className="icon" color="white" />
                  </div>
                  <div className="card-content">
                    <h6>issue Resolution Assistance</h6>
                    <p1 style={{ fontSize: 14 }}>
                      If an issue happens, Contractor Connection can help find a
                      resolution with your contractor.
                    </p1>
                  </div>
                  <br />
                </Card>
              </Col>
            </Row>
            <br />
            <br />
          </div>
        </Container>
        <Container fluid className="improve">
          <div className="improve-content">
            <Row>
              <Col sm={6}>
                <Row className="card-row">
                  <Col>
                    <Card className="improve-carti">
                      <Card.Body className="improve-carti-body one">
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          Contrary To Popular Belief
                        </h4>
                        <br />
                        <div style={{ color: "gray" }}>
                          <p1>
                            Remodeling a property remotely to completion can be
                            done safe and easy with professionally vetted
                            contractors.
                          </p1>
                          <br /> <br />
                          <li>General contractor services</li>
                          <li>Roofing installation and repair</li>
                          <li>Remodeling services</li>
                          <li>Flooring and carpet installation</li>
                          <li>Special needs modification</li>
                          <li>Gutters and siding</li>
                          <li>Carpet and upholstery cleaning</li>
                          <br />
                          <p1>
                            Referral services may not be available in all areas;
                            see what services are offered near you.
                          </p1>
                          <br />
                          <br />
                          <Link to="./login">
                            <Button className="improvement-button">
                              {" "}
                              Get Started <BsArrowRight size={20} />
                            </Button>
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col className="improve-img">
                <Row>
                  <div className="improve-body"></div>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <Container fluid className="improve">
          <div className="improve-content">
            <Row>
              <Col sm={6} className="improve-img-second ">
                <Row>
                  <div className="improve-body"></div>
                </Row>
              </Col>
              <Col>
                <Row className="card-row">
                  <Col>
                    <Card className="improve-carti">
                      <Card.Body className="improve-carti-body">
                        <br />
                        <h4 style={{ fontWeight: "bold" }}>
                          In Need Of An Urgent Repair?
                        </h4>
                        <br />
                        <div style={{ color: "gray" }}>
                          <p1>
                            Pipe burst, broken door, and roof tarping services
                            are available 24 hours a day, seven days a week in
                            selected areas.
                          </p1>
                          <br />
                          <br />
                          <p1>
                            The InvestFar Home Improvement Network isn't an
                            insurance claims program. For more information on
                            homeowners insurance claims, visit our{" "}
                            <a>Claims Resource Center</a>.
                          </p1>
                          <br />
                          <br />
                          <Row className="btnmargin">
                            <Link to="./login">
                              <Button className="improvement-button">
                                Online Request <BsArrowRight size={20} />
                              </Button>
                            </Link>
                            <Button
                              style={{ marginLeft: "8px" }}
                              className="improvement-button"
                            >
                              <ImPhone size={12} /> Call 1-866-855-1191
                              <BsArrowRight size={20} />
                            </Button>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="getstarted">
          <div className="">
            <br />
            <br />
            <h3>FAQs</h3>
            <br />
            <br />
            <div className="collapse-container">
              <Row>
                <Col as={Col} sm={9}>
                  <Row>
                    <Button
                      name="q1"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      What Is the InvestFar Home Improvement Network?{" "}
                      <FaPlus
                        className="icon"
                        color="rgb(35,119,186)"
                        style={{
                          background: "white",
                          width: "3%",
                          left: "97%",
                        }}
                      />
                    </Button>
                    <Collapse className="collapse-body" in={this.state.q1}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">insert info</div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="q2"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      Who manages the contractors that are apart of the Home
                      Improvement Network?{" "}
                      <FaPlus
                        className="icon"
                        color="rgb(35,119,186)"
                        style={{
                          background: "white",
                          width: "3%",
                          left: "97%",
                        }}
                      />
                    </Button>
                    <Collapse className="collapse-body" in={this.state.q2}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">insert info</div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="q3"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      How long does it take for a network contractor to contact
                      a member for general service requests?{" "}
                      <FaPlus
                        className="icon"
                        color="rgb(35,119,186)"
                        style={{
                          background: "white",
                          width: "3%",
                          left: "97%",
                        }}
                      />
                    </Button>
                    <Collapse className="collapse-body" in={this.state.q3}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">insert info</div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="q4"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      How long does it take for a network contractor to contact
                      the member for urgent requests?{" "}
                      <FaPlus
                        className="icon"
                        color="rgb(35,119,186)"
                        style={{
                          background: "white",
                          width: "3%",
                          left: "97%",
                        }}
                      />
                    </Button>
                    <Collapse className="collapse-body" in={this.state.q4}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">insert info</div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="q5"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      How long does the Home Improvement Network help you get a
                      fair and reasonable estimate?{" "}
                      <FaPlus
                        className="icon"
                        color="rgb(35,119,186)"
                        style={{
                          background: "white",
                          width: "3%",
                          left: "97%",
                        }}
                      />
                    </Button>
                    <Collapse className="collapse-body" in={this.state.q5}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">insert info</div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="q6"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      What is the benefit of selecting a network contractor to
                      the Investor Club members?{" "}
                      <FaPlus
                        className="icon"
                        color="rgb(35,119,186)"
                        style={{
                          background: "white",
                          width: "3%",
                          left: "97%",
                        }}
                      />
                    </Button>
                    <Collapse className="collapse-body" in={this.state.q6}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">insert info</div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="q7"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      How does InvestFar know that network contractors are
                      performing to program standards?{" "}
                      <FaPlus
                        className="icon"
                        color="rgb(35,119,186)"
                        style={{
                          background: "white",
                          width: "3%",
                          left: "97%",
                        }}
                      />
                    </Button>
                    <Collapse className="collapse-body" in={this.state.q7}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">insert info</div>
                      </div>
                    </Collapse>
                  </Row>
                </Col>
                <Col as={Col} sm={3}>
                  <Card className="faqcard">
                    <div className="faqicon-container">
                      <FaHome className="icon" color="rgb(35,119,186)" />
                    </div>
                    <div className="faqcard-content">
                      <p1 style={{ color: "white" }}>
                        Invest remotely, safely, easily, and with protection
                      </p1>
                    </div>
                    <br />
                    <br />
                    <br />
                  </Card>
                </Col>
              </Row>
              <div className="improve-footer">
                <ol>
                  <br />
                  <li>
                    Access to a network of pre-screened licensed contractors
                    selected specifically for your home improvement or urgent
                    home repair needs.
                  </li>

                  <li>
                    Contractor Connection's Quality Assurance team reviews all
                    proposals to help contractors provide a fair and reasonable
                    estimate for the services requested.
                  </li>

                  <li>
                    Should an issue arise, Contractor Connection will help
                    mediate communication between you and your contractor. When
                    needed, InvestFar will assist you with the process of issue
                    resolution.
                  </li>

                  <li>
                    The Home Improvement Network provides referrals to a network
                    of independent contractors managed by Contractor Connection.
                    Network contractors may use subcontractors when handling
                    certain assignments. Neither Contractor Connection nor
                    InvestFar, incscreens or performs background checks on
                    subcontractors. InvestFar inc and our respective affiliates,
                    subsidiaries, and related companies are not responsible for
                    the acts or omissions of network contractors, subcontractors
                    or Contractor Connection. Three-Year Workmanship Warranty
                    provided by the network contractor. Home Improvement
                    services only offered where InvestFar, includes business and
                    may not be available in all areas.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <br />
          <br />
        </Container>
      </div>
    );
  };

  render() {
    require("./HomeImprovement.css");
    return <this.improve />;
  }
}

export default HomeImprovement;
