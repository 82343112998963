import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Container,
    Button,
    Spinner,
    Card
} from 'react-bootstrap'
import '../MyProperties/MyProperties.css'
import '../PropertySearch/PropertySearch.css'
import './AllBy.css'

import {
    BsTrash,
    BsPencilSquare
} from 'react-icons/bs'
import { User, CURRENT } from '../../Constructors/user'
import { Error, Warning, Primary, Info, Success } from '../../Alerts'
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { store } from 'react-notifications-component';
import { Link } from "react-router-dom";
import moment from 'moment'
import Far from '../../Spinners/Far'
import axios from 'axios';


var request = require('request');


export default function MyReviews(props) {
    const [propertyList, setProperties] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [current, setCurrent] = useState({})
    const [deleteProperty, setDeleteProperty] = useState(false)
    const [deleted, setDeleted] = useState(null)
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("passed Props", props)
        axios.get(`/api/properties/all-properties?id=${props.location.state.id}`, {}).then(res => {
            setLoading(false);
            if (res.data) {
                setProperties(res.data.msg)
            } else {
                setProperties([])
            }
        }).catch(error => {
            console.log("Encountered an error", error)
            setProperties([])
            Error({ title: 'Failed', message: 'Failed to load all by propeties' });
        })
        console.log('LOCATION PROPS', props.location)
        // var options = {
        //     'method': 'GET',
        //     'url': `http://localhost:8000/properties/all-properties?id=${props.location.state.id}`,
        //     'headers': {
        //     }
        // };
        // request(options, function (error, response) {
        //     if (error) {
        //         setProperties([])
        //     } else {
        //         setProperties(JSON.parse(response.body).msg)
        //         setName(props.location.state.name)
        //     }
        //     setLoading(false)
        // });

    }, [])


    var Property = (props) => {

        var background
        if (props.index % 2 == 0) {
            background = 'rgba(209, 209, 218, 0.2)'
        } else {
            background = '#fff'
        }
        return (
            <tr className='proptables-row' style={{ backgroundColor: background }}>
                <td><div className='header-elem' style={{ margin: '20px' }}>{props.index}</div></td>
                <td>
                    <Link to={{
                        pathname: '/property',
                        latest: false,
                        state: {
                            id: props.current.property_id, search: `${current.id}`,
                        },
                        search: `${current.id}`,

                    }}>
                        <img className='header-elem' style={{ width: '150px' }} src={props.current.image_url} />

                    </Link>
                </td>
                <td><div className='header-elem'>{props.current.title}</div></td>
                <td><div className='header-elem'>{props.current.status}</div></td>
            </tr >
        )
    }
    return (
        <div className='submit-property-page property'>
            <Container fluid className="jumbo">
                <Row>
                    <Col sm={5}>
                        <div className="jumbo-content-left">
                            <h2>ALL BY {propertyList && propertyList[0] ? propertyList[0].owner.name.toUpperCase() : ""}</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="my-properties-body">
                {loading ?
                    (<div style={{ textAlign: 'center' }}>
                        <h5 className='loading'>Loading</h5>
                        <div style={{ height: '20px' }} />
                        {/* <Spinner variant='primary' animation='border' /> */}
                        <Far />
                        <div style={{ height: '100px' }} />

                    </div>
                    )
                    :
                    (
                        <div>
                            {
                                (!propertyList || propertyList.length < 1 && !loading) ?
                                    (
                                        <Container>
                                            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                                <Container>
                                                    <h4>NO PROPERTIES</h4>
                                                    <p></p>
                                                    <p></p>
                                                    <p>No properties found</p>                                                <p></p>
                                                    <p></p>
                                                </Container>

                                            </div>
                                        </Container>
                                    ) :
                                    (
                                        <div className='listings'>
                                            <Row><p>{propertyList.length} FOUND</p></Row>
                                            <Row>
                                                {propertyList.map((listing, key) => {
                                                    return (
                                                        <Col sm={3}>

                                                            <Link to={{
                                                                pathname: '/property',
                                                                propertyProps: listing.name,
                                                                currentProperty: listing
                                                            }} style={{ textDecoration: 'none' }}>
                                                                <Card className="list-card">
                                                                    <div>
                                                                        <Row className='listing-img' style={{
                                                                            backgroundImage: `url(${listing.images[0] ? listing.images[0] : 'https://storage.googleapis.com/hale-monument-279303.appspot.com/202006290031081593390668dnk0m8mdu5nxydbmb1754caov.jpg'})`,
                                                                        }}>
                                                                        </Row>
                                                                        <Row className='listing-inner'>
                                                                            <Col>
                                                                                <Row className='header'>
                                                                                    <p>{listing.title}</p>
                                                                                </Row>
                                                                                <Row><p>{listing.location.full.replace(', undefined', '')}</p></Row>
                                                                                <Row className='date-range'><p>{moment(new Date(listing.description.timestamp)).calendar()}</p></Row>
                                                                                {/* <Row className='text-center'>
                                                                                    <Button className='details-button'>Details</Button>
                                                                                </Row> */}
                                                                            </Col>
                                                                        </Row>

                                                                    </div>
                                                                </Card>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    )
                            }
                        </div>


                    )
                }
                <div>
                </div>
                <br />
            </Container>
        </div>
    )
}

